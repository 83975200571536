<div
	class="reset-password-container d-flex justify-content-center align-items-center"
>
	<div class="digital-shelf-logo">
		<img
			height="3.75rem"
			width="5.875rem"
			src="/assets/images/DS logo.svg"
			alt=""
		/>
	</div>
	<div class="inner-container">
		<div class="header d-flex justify-content-between align-items-center">
			<h4 class="modal-title" translate ngbAutoFocus>Forgot Password</h4>
		</div>
		<form
			[formGroup]="forgotPasswordForm"
			class="forgotPasswordForm"
			(keydown.enter)="$event.preventDefault()"
			(ngSubmit)="onSubmit()"
		>
			<div
				class="input-container d-flex justify-content-between align-items-center"
			>
				<label for="emailID" translate>Email Id<sup>*</sup></label>
				<input
					type="text"
					id="emailID"
					placeholder="{{'Enter Email id' | translate}}"
					autocomplete="off"
					[ngClass]="{ error: forgotPasswordForm.controls['email_id'].invalid && (forgotPasswordForm.controls['email_id'].dirty || forgotPasswordForm.controls['email_id'].touched) }"
					formControlName="email_id"
				/>
			</div>
			<div
				class="errorLable"
				*ngIf="forgotPasswordForm.controls['email_id'].invalid &&
            (forgotPasswordForm.controls['email_id'].dirty ||
            forgotPasswordForm.controls['email_id'].touched)
          "
			>
				<span
					*ngIf="forgotPasswordForm.controls['email_id'].errors.pattern"
					translate
					>Please enter valid email Id.</span
				>
				<span
					*ngIf="forgotPasswordForm.controls['email_id'].errors.required"
					translate
					>Please enter email Id.</span
				>
				<span
					*ngIf="forgotPasswordForm.controls['email_id'].errors.emailError"
					translate
					>{{forgotPasswordForm.controls['email_id'].errors['emailError']}}</span
				>
			</div>
			<div class="footer d-flex justify-content-end gap-16">
				<button
					class="btn btn-secondary btn-lg"
					(click)="goBackToLogin()"
					translate
				>
					Back To login
				</button>
				<button type="submit" class="btn btn-primary btn-lg" translate>
					Send Reset Link
				</button>
			</div>
		</form>
	</div>
</div>
<router-outlet></router-outlet>
