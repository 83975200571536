import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject, debounceTime, distinctUntilChanged, filter, takeUntil, tap } from "rxjs";
import { UntypedFormControl } from "@angular/forms";
import { LocalStorageService } from "@ds-common-services/storage-services/local-storage.service";
import { CommonFunctionsService } from "@ds-shared/common-services/utility-services/common-functions.service";

@Component({
  selector: 'app-search-common',
  templateUrl: './search-common.component.html',
  styleUrls: ['./search-common.component.scss']
})
export class SearchCommonComponent implements OnInit {

  @Input() public searchObj: any;
  @Input() public isScorecard = false;
  @Output("onSearch") onSearch: EventEmitter<any> = new EventEmitter();
  private destroy$: Subject<boolean> = new Subject();
  public searchedName: UntypedFormControl = new UntypedFormControl('');
  
  constructor(
		private localStorageService: LocalStorageService,
		public commonFunctions: CommonFunctionsService
  ) { }

  ngOnInit(): void {
    this.searchInit();
    if(this.isScorecard && this.localStorageService.get(this.localStorageService.scorecardSearched)){
      this.searchedName.setValue(this.commonFunctions.getDecodedData(
        this.localStorageService.get(this.localStorageService.scorecardSearched)
      ))
    }
  }
  public ngOnChanges(changes: SimpleChanges): void{
    if (changes.searchObj.currentValue) {
      if (!this.searchObj?.text?.length) this.searchedName.reset(null);
    }
  }
  searchInit() {
    this.searchedName?.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter((res)=>res!==null),
        takeUntil(this.destroy$),
      )
      .subscribe((res: any) => {
        this.onSearch.emit(res);
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
