import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumb-common',
  templateUrl: './breadcrumb-common.component.html',
  styleUrls: ['./breadcrumb-common.component.scss']
})
export class BreadcrumbCommonComponent implements OnInit {

  @Input() public data: string[] = [];
  @Input() public routeActive: boolean = false;
  @Input() public maxLength:number = 250;
  constructor(private route:ActivatedRoute, private router:Router) { }

  ngOnInit(): void {
  }
 
  public navigateBack(index: number): void {
    let navigatePath;
    if (index !== this.data.length - 1) {
      if(this.route['_routerState']['snapshot']['url'].includes('/discover/product-compare') || this.route['_routerState']['snapshot']['url'].includes('/discover/category') ||  this.route['_routerState']['snapshot']['url'].includes('/discover/store-compare/')) {
        navigatePath = 'discover/products'
      }
      else {
        navigatePath = this.route.snapshot['_urlSegment'].segments.reduce((res:string, item:any, i:number) => {
          if (i <= index) return res + `/${item.path}`;
          else return res;
        }, '');
      }
      this.router.navigate([navigatePath]);
    }
  }

}
