import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { CommonFunctionsService } from './common-functions.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor(private http: HttpClient,
    private translateService: TranslateService, private commonFunctions: CommonFunctionsService) { }
  
  public getBrowsersLocals():any {
    let languageType = this.commonFunctions.getLang();
    this.translateService.addLangs(["en", "fr"]);
    this.translateService.setDefaultLang(`${languageType.code}/locale`);
    this.translateService.use(`${languageType.code}/locale`);
    return languageType;
  }
}
