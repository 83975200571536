<div ngbDropdown class="d-inline-block position-static" display="dynamic">
   <span id="dropdownBasic1" ngbDropdownToggle class="cursor-pointer"><span class="team-options"><i
            class="icon icon-Options"></i></span></span>
   <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="grid-action-dropdown"
      [ngClass]="{'dropdownTop':(col+1==selectedPage_size || col+2==selectedPage_size)}">
      <button class="dropdown-item" (click)="editUser('edit')"
         [disabled]="(getPermissionStatus(permissions) && !this.actionData?.attributes?.is_active) || (!getPermissionStatus(permissions))"><i
            class="icon  icon-Edit-alt"></i><span translate>Edit User</span></button>
      <button [disabled]="!getPermissionStatus(permissions)" class="dropdown-item"
         (click)="openModal(content1); getProfiles()"><i class='icon icon-Trash' ngbAutofocus></i><span translate>Delete
            User</span></button>
      <!-- <button class="dropdown-item"><i class="icon  icon-User-plus"></i><span>Create Similar User</span></button>
       <button class="dropdown-item"><i class="icon  icon-Copy"></i><span>Copy Permission</span></button> -->
      <button [disabled]="!getPermissionStatus(permissions)" class="dropdown-item" (click)="openModal(content)"><i
            class="{{actionData?.attributes?.is_active?'icon icon-User-block':'icon icon-User'}}"></i>
         <span translate>{{this.actionData?.attributes?.is_active ? 'Deactivate User' : 'Activate
            User'}}</span></button>
   </div>
</div>
<ng-template #content let-modal>
   <div class="modal-header">
      <h4 class="modal-title" translate>{{actionData?.attributes?.is_active?"Deactivate User":"Activate User"}}</h4>
   </div>
   <div class="modal-body justify-content-center align-items-center">
      <i class="{{this.actionData?.attributes?.is_active?'icon icon-User-block':'icon icon-User'}}" ngbAutofocus></i>
      <p translate>Are you sure you want to {{actionData?.attributes?.is_active ? 'deactivate' : 'activate'}} this user?
      </p>
   </div>
   <div class="modal-footer">
      <button type="button" class="btn btn-secondary btn-lg" (click)="modal.close('Close click')"
         translate>Cancel</button>
      <button type="button" class="btn btn-primary btn-lg" (click)="[deactivateUser(), modal.close('Close click')]"
         translate>Yes</button>
   </div>
</ng-template>

<ng-template #content1 let-modal>
   <div class="modal-header">
      <h4 class="modal-title" translate>Delete User</h4>
   </div>
   <div class="modal-body p-0">
      <!-- <i class='icon icon-Trash' ngbAutofocus></i> -->
      <p class="ds-bold profile-heading">There are {{profileList?.count ? profileList?.count : 0}} profiles under
         {{actionData.attributes.name}}</p>
      <div class="table-container" *ngIf="!profileListLoading && profileList?.profile_list?.length">
         <div class="d-flex justify-content-between" *ngFor="let profile of profileList?.profile_list">
            <div>{{profile?.name}}</div>
            <div><span class="badge badge-medium-grey">{{profile?.is_customer ? 'Your ' : 'Competitor
                  '}}{{profile?.profile_type}}</span></div>
         </div>
      </div>
      <div class="d-flex align-items-center justify-content-center">
         <div class="no-data-common w-100" *ngIf="!profileList?.profile_list?.length">
            <div *ngIf="!profileListLoading && !profileList?.profile_list?.length" class="no-data-found" translate>
               <div class="img"><i class="icon icon-Search"></i></div>
               <div class="no-data-text" translate>No data present</div>
            </div>
            <div *ngIf="profileListLoading" class="loading-panel">
               <img ngSrc="../../../../../assets/images/spinner.svg" alt=""  height="200" width="200"/>
            </div>
         </div>
      </div>
   </div>
   <ng-template [ngIf]="!profileListLoading && profileList?.profile_list?.length">
      <label class="radio" for="default" (click)="onDeleteOptionSelect('MoveDelete')">
         <input type="radio" name="radio" id="default" checked>
         <div class="circle"></div>
         <span>Move Profiles and Delete</span>
      </label>
      <span class="info">Move all the above profiles to your own account before deleting from the user.</span>
      <label class="radio" for="selected" (click)="onDeleteOptionSelect('Delete')">
         <input type="radio" name="radio" id="selected">
         <div class="circle"></div>
         <span> Delete without Moving</span>
      </label>
      <span class="info">Directly delete the user and all the above profiles without moving.
      </span>
   </ng-template>
  
   <ng-template [ngIf]="!profileListLoading && !profileList?.profile_list?.length">
      <label class="radio" for="default" (click)="onDeleteOptionSelect('Delete')">
         <input type="radio" name="radio" id="default" checked>
         <div class="circle"></div>
         <span>Delete Profiles</span>
      </label>
   </ng-template>

   <div class="modal-footer p-0">
      <button type="button" class="btn btn-primary btn-lg" (click)="[onConfirmDeleteUser(), modal.close('Close click')]"
         translate>Confirm</button>
      <button type="button" class="btn btn-secondary btn-lg" (click)="modal.close('Close click')"
         translate>Cancel</button>
   </div>
</ng-template>