import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Subject, filter, takeUntil } from 'rxjs';
import { NotificationsService } from './notifications.service';
import { RolePermissions } from '@ds-models/access-matrix.model';
import { AccessMatrixService } from '@ds-common-services/utility-services/access-matrix.service';
import { TranslationService } from '@ds-common-services/utility-services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonFunctionsService } from '@ds-common-services/utility-services/common-functions.service';
import { DataTransferService } from '@ds-common-services/utility-services/data-transfer.service';
import { CommonSearchModel } from '@ds-shared/models/common.model';
import { TabListModel } from '@ds-shared/models/notification.model';

@Component({
	selector: "app-notifications",
	templateUrl: "./notifications.component.html",
	styleUrls: ["./notifications.component.scss"]
})
export class NotificationsComponent implements OnInit {
	public NotificationBreadcrumb: string[] = ["Notification Intelligence"];
	public notificationInfo: string = "";
	public allNotificationsResponse: any;
	public allHistoricNotificationsResponse: any;
	public mappedType: string = "store";
	public recentPageNumber: number;
	public historicPageNumber: number;
	public isMetricView: boolean = false;
	public activatedComponentReference: any;
	public searchedName: UntypedFormControl = new UntypedFormControl("");
	public isRecentPresent: boolean = false;
	public isHistoricPresent: boolean = false;
	public destroy$: Subject<boolean> = new Subject();
	public permissions: RolePermissions = new RolePermissions();
	public recentCount:number;
	public searchObj: CommonSearchModel = {
		text: "",
		placeholder: "Search Store",
		style: { width: "19rem" }
	};
	public isOpen: boolean = false;
	public active = 0;
	public tabList: TabListModel[] = [
		{
			id: 1,
			name: "Stores",
			value: "store",
			routePath: 'stores'
		},
    {
			id: 2,
			name: "Products",
			value: "product",
			routePath: 'products'
		},
    {
			id: 3,
			name: "Keywords",
			value: "keyword",
			routePath: 'keywords'
		},
    {
			id: 4,
			name: "Categories",
			value: "category",
			routePath: 'categories'
		},
    {
			id: 5,
			name: "Brands",
			value: "brand",
			routePath: 'brands'
		}
	];
	public notificationTypeList = [{
		id: 1,
		name: 'Recent'
	},
	{
		id: 2,
		name: 'Historic'
	}]
	constructor(
		private notificationService: NotificationsService,
		private router: Router,
		private route: ActivatedRoute,
		private accessMatrixService: AccessMatrixService,
		private translate: TranslateService,
		public translationService: TranslationService,
		public commonFunctions: CommonFunctionsService,
		public dataTransferService: DataTransferService
	) {
		this.permissions = this.accessMatrixService.getPermissionByRoute();
		this.setUpdatedLang();
	}

	ngOnInit(): void {
		this.dataTransferService.sendfireMenuCount('notification');
		this.notificationInfo =
			"Set alerts to monitor trends for you and your competitors' assortments";
	}
	onMappedUpdate(type) {
		if (type.value != this.mappedType) {
			this.searchedName.reset("", { emitEvent: false });
			this.isMetricView = false;
			this.router.navigate(["notification/" + type.routePath]);
		}
	}
	resetData(type) {
		this.mappedType = this.getTypeFromRoute(type);
		this.getNotificationsData();
		this.getHistoricNotificationsData();
		this.searchObj.text='';
		this.searchObj = {
			...this.searchObj,
			placeholder: this.getPlaceholderForSearch()
		};
	}

	getNotificationsData() {
		this.recentPageNumber = 1;
		this.allNotificationsResponse = null;
		this.isRecentPresent = false;
		this.notificationService
			.getNotifications(
				this.isMetricView,
				this.mappedType,
				this.recentPageNumber
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe(
				(res: any) => {
					if (res) {
						this.allNotificationsResponse = res["data"];
						this.recentPageNumber = res["meta"]["next_page"];
						this.recentCount = res["meta"]["total_count"]
						this.isRecentPresent = true;
					}
				},
				(err: any) => {
					this.allNotificationsResponse = null;
					this.recentPageNumber = 1;
					this.isRecentPresent = true;
				}
			);
	}

	getHistoricNotificationsData() {
		this.historicPageNumber = 1;
		this.allHistoricNotificationsResponse = null;
		this.isHistoricPresent = false;
		this.notificationService
			.getHistoricNotifications(
				this.isMetricView,
				this.mappedType,
				this.historicPageNumber
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe(
				(res: any) => {
					if (res) {
						this.allHistoricNotificationsResponse = res["data"];
						this.historicPageNumber = res["meta"]["next_page"];
						this.isHistoricPresent = true;
					}
				},
				(err: any) => {
					this.allHistoricNotificationsResponse = null;
					this.historicPageNumber = 1;
					this.isHistoricPresent = true;
				}
			);
	}

	addNotification() {
		let routePath = this.route.snapshot["_urlSegment"].segments[1].path
		this.router.navigate([`notification/${routePath}/add`]);
	}

	onActivate(event: any) {
		if(this.NotificationBreadcrumb.length > 1) this.NotificationBreadcrumb.pop();
		this.activatedComponentReference = event;
		this.activatedComponentReference.componentDetail = event;
		const breadcrumbText = this.route.snapshot["_urlSegment"].segments[1].path === 'categories' ? 'category' : this.route.snapshot["_urlSegment"].segments[1].path.slice(0, -1); 
		this.NotificationBreadcrumb.push(breadcrumbText);
		this.resetData(this.route.snapshot["_urlSegment"].segments[1].path);
	}

	onrefreshRecentNotifications(eve) {
		this.recentPageNumber = 1;
		this.isOpen = !this.isOpen;
		this.allNotificationsResponse = null;
		this.resetData(this.route.snapshot["_urlSegment"].segments[1].path);
	}

	onrefreshHistoricNotifications(eve) {
		this.historicPageNumber = 1;
		this.isOpen = !this.isOpen;
		this.allNotificationsResponse = null;
		this.resetData(this.route.snapshot["_urlSegment"].segments[1].path);
	}

	getPlaceholderForSearch() {
    let mappedTypeCap = this.mappedType[0].toUpperCase() + this.mappedType.slice(1);
    return ("Search "+mappedTypeCap);
	}
	setUpdatedLang() {
		let lang = this.translationService.getBrowsersLocals();
		this.translate.use(`${lang.code}/locale`);
	}
	public onSearch(text: string): void {
		this.searchObj = { ...this.searchObj, text: text };
		this.activatedComponentReference.searchedKeyword(this.searchObj);
	}
	openNotification() {
		this.active = 0;
		this.isOpen = !this.isOpen;
	}

	openHistoricNotification() {
		this.isOpen = !this.isOpen;
	}

	private getTypeFromRoute(type){
		const obj = this.tabList.find(o => o.routePath === type);
		return obj.value
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.complete();
	}
}
