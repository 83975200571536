import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { LocalStorageService } from "@ds-common-services/storage-services/local-storage.service";
import { NotificationService } from "@ds-common-services/utility-services/notification.service";
import { Router } from "@angular/router";
import { GlobalService } from "@ds-common-services/http-services/global.service";
import { CommonFunctionsService } from "@ds-common-services/utility-services/common-functions.service";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
	private token: any;
	public errorCounter = {
		count: 0,
		type: null
	};
	isAlreadyProcessesedByHttpInterceptor = false;
	constructor(
		public note: NotificationService,
		private localStorageService: LocalStorageService,
		private globalService: GlobalService,
		private router: Router,
		private commonFunctions: CommonFunctionsService
	) {
	}

	intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<unknown>> {
		this.isAlreadyProcessesedByHttpInterceptor = true;
		let headers = request.headers;

		// Add custom headers
		headers = this.addCustomHeaders(headers as HttpHeaders);

		// Clone the request with the new headers
		const authReq = request.clone({ headers });
		return next.handle(authReq).pipe(
			tap((data: any) => {
				if (data?.status) {
					this.errorCounter.type = data.status;
					this.errorCounter.count = 0;
				}
			}),
			catchError((errorResponse: any) => {
				let errMsg: string;
				this.errorCounter.type = errorResponse.status;
				if (errorResponse.status === 401 || errorResponse.status === 403) {
					this.errorCounter.count++;
				}
				if (errorResponse instanceof HttpErrorResponse) {
					const err =
						errorResponse.message || JSON.stringify(errorResponse.error);
					errMsg = `${errorResponse.status} - ${errorResponse.statusText}`;
				} else {
					errMsg = errorResponse.message
						? errorResponse.message
						: errorResponse.toString();
				}
				if (errorResponse.status === 401) {
					this.globalService.logout();
					if (this.errorCounter.count === 1)
						this.note.setMessage(
							1100,
							this.commonFunctions.getSpecificTranslation("Session Expired!")
						);
				}
				return throwError(errorResponse);
			})
		);
	}

	// The ngx-jsonapi package bypasses the HttpClient request pipeline. To handle this package's behavior, we have implemented an XML Middleware.
	private overrideXMLHttpRequest = (() => {
			const originalXhrOpen = XMLHttpRequest.prototype.open;
			const originalXhrSend = XMLHttpRequest.prototype.send;
			const interceptorInstance = this;
			XMLHttpRequest.prototype.open = function (method, url, ...rest) {
				const normalizedUrl = interceptorInstance.normalizeUrl(url);
        		this.url = normalizedUrl;
				return originalXhrOpen.apply(this, [method, normalizedUrl, ...rest]);
			};
	
			XMLHttpRequest.prototype.send = function (body) {
					// Use HttpHeaders to get keys and values
					if(!interceptorInstance.isAlreadyProcessesedByHttpInterceptor){
					const customHeaders = interceptorInstance.addCustomHeaders(new HttpHeaders());
						customHeaders.keys().forEach((key) => {
							this.setRequestHeader(key, customHeaders.get(key) || '');
						});
			}
			interceptorInstance.isAlreadyProcessesedByHttpInterceptor = false;
				return originalXhrSend.apply(this, [body]);
			};
		
	})();

	private normalizeUrl(url: string): string {
		try {
			const urlObj = new URL(url);
			const params = new URLSearchParams(urlObj.search);
	
			const updatedParams: string[] = [];
	
			params.forEach((value, key) => {
				let normalizedValue = value;
	
				if (this.isDoubleEncoded(value)) {
					normalizedValue = decodeURIComponent(value); // Decode if double-encoded
				}
	
				normalizedValue = encodeURIComponent(normalizedValue);
	
				updatedParams.push(`${key}=${normalizedValue}`);
			});
	
			const newSearch = updatedParams.join("&");
			urlObj.search = newSearch;
	
			return urlObj.toString();
		} catch (error) {
			return url;
		}
	}
	
	private isDoubleEncoded(value: string): boolean {
		try {
			const firstDecode = decodeURIComponent(value);
			return firstDecode !== value && encodeURIComponent(firstDecode) === value;
		} catch {
			return false; // If decoding fails, assume it's not double-encoded
		}
	}
	

	private addCustomHeaders(headers: HttpHeaders): HttpHeaders {
		const token = this.localStorageService.get("jwt");
		const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		let isHarmonisationOn = "false";
		let customerId = "null";

		try {
			const userStorageData = this.localStorageService.get(this.localStorageService.userStorageKey);
			const userDecodedData = userStorageData
				? JSON.parse(this.commonFunctions.getDecodedData(userStorageData))
				: null;

			if (userDecodedData) {
				isHarmonisationOn = userDecodedData["data"]["harmonised_view"].toString();
				customerId = window.btoa(userDecodedData["data"]["customer"]["id"]);
			}
		} catch {
			// Defaults are already set
		}

		// Add headers
		// Check each header and set only if it doesn't already exist
		if (!headers.has("Authorization") && token) {
			headers = headers.set("Authorization", `Bearer ${token}`);
		}
		if (!headers.has("X-Timezone")) {
			headers = headers.set("X-Timezone", timeZone);
		}
		if (!headers.has("X-Harmonisation")) {
			headers = headers.set("X-Harmonisation", isHarmonisationOn);
		}
		if (!headers.has("X-Customer")) {
			headers = headers.set("X-Customer", customerId);
		}
	
		return headers;

	}
}
