import { Injectable, ErrorHandler } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {
  handleError(error: any): void {
    const chunkFailedMessage = 'ChunkLoadError: Loading chunk';
    const mimeTypeMessage = 'server responded with a MIME type';
    if (error.message.includes(chunkFailedMessage) || error.message.includes(mimeTypeMessage)) {
      window.location.reload();
    }
  }
}
