import {
	AfterViewInit,
	Component,
	ElementRef,
	OnInit,
	ViewChild
} from "@angular/core";
import {
	ActivatedRoute,
	NavigationEnd,
	Router,
	RouterEvent
} from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subject, Subscription } from "rxjs";
import { debounceTime, filter, finalize, takeUntil } from "rxjs/operators";
import { CommonFunctionsService } from "@ds-common-services/utility-services/common-functions.service";
import { DataTransferService } from "@ds-common-services/utility-services/data-transfer.service";
import { TranslationService } from "@ds-common-services/utility-services/translation.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AccessMatrixService } from "@ds-shared/common-services/utility-services/access-matrix.service";
import { GlobalService } from "@ds-shared/common-services/http-services/global.service";
import { MixpanelService } from "@ds-common-services/utility-services/mixpanel.service";
import {
	SearchResult,
	globalSearchPayload
} from "@ds-shared/models/common.model";
import { productFruits } from "product-fruits";
import { environment } from "@ds-env/environment";
import { LocalStorageService } from "@ds-shared/common-services/storage-services/local-storage.service";

declare global {
	interface Window {
		productFruits: {
			init: (
				workspaceCode: string,
				language: string,
				userObject: ProductFruitsUserObject
			) => void;
			services: {
				destroy: () => void;
			};
		};
	}
}
@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, AfterViewInit {
	@ViewChild("globalSearch") popUp: ElementRef;
	title = "digital-shelf";
	public status: boolean = false;
	public activeModules: string = "";
	public languageChangeSubscription: Subscription;
	public searchedText: string = "";
	public searchedResult = {
		loading: false,
		data: []
	};
	public firstIndex;
	public searchType = {
		key: "searchType",
		multiple: false,
		list: [
			{
				id: 0,
				attributes: {
					type: this.commonFunctions.getSpecificTranslation("Product"),
					key: "product"
				}
			},
			{
				id: 1,
				attributes: {
					type: this.commonFunctions.getSpecificTranslation("Store"),
					key: "store"
				}
			},
			{
				id: 2,
				attributes: {
					type: this.commonFunctions.getSpecificTranslation("Brand"),
					key: "brand"
				}
			},
			{
				id: 3,
				attributes: {
					type: this.commonFunctions.getSpecificTranslation("Keyword"),
					key: "keyword"
				}
			},
			{
				id: 4,
				attributes: {
					type: this.commonFunctions.getSpecificTranslation("Category"),
					key: "category"
				}
			}
		],
		selected: {
			id: 0,
			attributes: {
				type: this.commonFunctions.getSpecificTranslation("Product"),
				key: "product"
			}
		},
		dependencyStatus: null,
		placeholder: "Search",
		loading: false,
		search: null,
		closeOnSelect: true,
		disabled: false
	};
	public recentSearchList = {
		loading: false,
		data: []
	};
	public searchSubject$ = new Subject<string>();
	public notFoundText: string = this.commonFunctions.getSpecificTranslation(
		"Please enter atleast 3 letters to search"
	);
	private destroy$: Subject<boolean> = new Subject();
	constructor(
		public route: ActivatedRoute,
		public router: Router,
		private dataTransferService: DataTransferService,
		private translate: TranslateService,
		public translationService: TranslationService,
		private commonFunctions: CommonFunctionsService,
		private modalService: NgbModal,
		public accessMatrixService: AccessMatrixService,
		private globalService: GlobalService,
		private mixpanelService: MixpanelService,
		private localStorageService: LocalStorageService
	) {
		this.getUpdatedLanguage();
	}

	ngOnInit(): void {
		this.mixpanelService.init();
		this.router.events
			.pipe(filter((e: any): e is RouterEvent => e instanceof RouterEvent))
			.subscribe((evt: RouterEvent) => {
				if (evt instanceof NavigationEnd) {
					this.activeModules = evt?.url?.split("/")?.[1];
					if (
						this.route["_routerState"]["snapshot"].url.length <= 1 ||
						String(this.route["_routerState"]["snapshot"].url).includes(
							"/sample"
						) ||
						String(this.route["_routerState"]["snapshot"].url).includes(
							"/reset-password"
						) ||
						String(this.route["_routerState"]["snapshot"].url).includes(
							"/forgot-password"
						)
					)
						this.status = false;
					else this.status = true;
				}
			});
		this.loginWatcher();
		this.searchInit();
		if (this.accessMatrixService.isAuthenticated()) {
			this.loadProductFruitGuides();
		}
		this.actionBeforeReload();
	}

	ngAfterViewInit() {
		this.commonFunctions.watchSecondaryMenu();
	}

	private loginWatcher() {
		this.dataTransferService.afterLoginSuccess$
			.pipe(
				filter((res) => res),
				takeUntil(this.destroy$)
			)
			.subscribe((res: boolean) => {
				this.loadProductFruitGuides();
			});
	}
	getUpdatedLanguage() {
		let lang = this.translationService.getBrowsersLocals();
		this.translate.use(`${lang.code}/locale`);
		this.commonFunctions.rtlStatus = lang.rtl;
		if (lang?.rtl)
			document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
		else document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
	}
	getRecentSearch(type: string) {
		this.recentSearchList.loading = true;
		this.recentSearchList.data = [];
		this.globalService
			.getRecentGlobalSearch(type)
			.pipe(
				finalize(() => (this.recentSearchList.loading = false)),
				takeUntil(this.destroy$)
			)
			.subscribe((res: any) => {
				if (res.data?.length > 0) {
					const recentText =
						res.data.length === 1
							? `${this.commonFunctions.getSpecificTranslation(
									"Recently searched"
							  )} ${type}`
							: `${this.commonFunctions.getSpecificTranslation(
									"Recently searched"
							  )} ${type === "category" ? "categories" : type + "s"}`;
					this.recentSearchList.data = [
						{ attributes: { name: recentText }, disabled: true },
						...res.data
					];
				}
			});
	}
	openModal() {
		this.resetSearchData();
		this.searchedText = "";
		this.searchType.selected = this.searchType.list[0];
		this.modalService.open(this.popUp, {
			centered: true,
			windowClass: "popUpModal globalSearch",
			keyboard: false,
			backdrop: "static"
		});
		this.getRecentSearch(this.searchType.selected.attributes.key);
	}
	onSearchTypeChange(event) {
		this.searchedText = "";
		this.resetSearchData();
		this.searchType.selected = { ...event };
		this.getRecentSearch(event.attributes.key);
	}
	resetSearchData() {
		this.searchedResult.data = [];
		this.searchedResult.loading = false;
	}
	searchInit() {
		this.searchSubject$
			.pipe(debounceTime(500), takeUntil(this.destroy$))
			.subscribe((term) => {
				this.resetSearchData();
				if (term?.length > 0) this.searchFnCall();
			});
	}
	onSearch(event) {
		this.searchedText = event.term;
		this.searchSubject$.next(event.term);
	}
	searchFnCall() {
		if (this.searchedText.length >= 3) {
			const payload: globalSearchPayload = {
				search_term: this.searchedText,
				search_type: this.searchType.selected.attributes.key,
				currency_id: this.commonFunctions.currencySelected?.id,
				limit: 50
			};
			this.searchedResult.data = [];
			this.searchedResult.loading = true;
			this.globalService
				.callAdvanceGlobalSearch(payload)
				.pipe(
					finalize(() => (this.searchedResult.loading = false)),
					takeUntil(this.destroy$)
				)
				.subscribe((res: any) => {
					if (res.data?.length > 0) {
						this.firstIndex = res.data.findIndex(this.findFirstIndex);
						const didYouMean = {
							attributes: {
								name: this.commonFunctions.getSpecificTranslation(
									"Did you mean?"
								)
							},
							disabled: true
						};
						this.searchedResult.data =
							this.firstIndex > -1
								? [
										...res.data.slice(0, this.firstIndex),
										didYouMean,
										...res.data.slice(this.firstIndex)
								  ]
								: res.data;
					} else {
						this.notFoundText = this.commonFunctions.getSpecificTranslation(
							"No relevant result found"
						);
					}
				});
		} else {
			this.notFoundText = this.commonFunctions.getSpecificTranslation(
				"Please enter atleast 3 letters to search"
			);
		}
	}
	findFirstIndex = (ele: SearchResult) => ele.attributes.is_substring === false;

	onSearchValueSelection(event) {
		const payload: globalSearchPayload = {
			search_term: event.attributes.name,
			search_type: this.searchType.selected.attributes.key,
			currency_id: this.commonFunctions.currencySelected?.id,
			search_id: event.id
		};
		this.globalService
			.callAdvanceGlobalRecentSearch(payload)
			.pipe()
			.subscribe((res: any) => {});
		this.router.navigate([
			`/scorecard/${this.searchType.selected.attributes.key?.toLocaleLowerCase()}/${
				event.id
			}`
		]);
		this.modalService.dismissAll();
	}
	customSearchFn(term: string, item: any) {
		return item;
	}

	loadProductFruitGuides = () => {
		const userDetails = this.commonFunctions.getSavedUserData();
		const user: ProductFruitsUserObject = {
			username: userDetails.first_name,
			firstname: userDetails.first_name,
			lastname: userDetails.last_name,
			role: userDetails.role.name,
			email: userDetails.email,
			props: {
				user: userDetails.id,
				cust: userDetails.customer.id
			}
		};
		productFruits.init(environment.DSPRODUCTFRUITAPI, "en", user, {
			disableLocationChangeDetection: false
		});
		productFruits.safeExec(($pf) => {
			// log if needed
		});
	};

	private actionBeforeReload(): void {
		window.onbeforeunload = (event) => {
			let latestMessage = this.localStorageService.get(
				this.localStorageService.latestDataMessageStorageKey
			);
			if (latestMessage) {
				latestMessage = JSON.parse(
					this.commonFunctions.getDecodedData(latestMessage)
				);
				latestMessage["isClosed"] = false;
				this.localStorageService.set(
					this.localStorageService.latestDataMessageStorageKey,
					this.commonFunctions.getEncodedData(JSON.stringify(latestMessage))
				);
			}
		};
	}
	onImgError(event) {
		event.target.src = "../../../../../assets/images/no-image.svg";
	}

	public ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}
}
