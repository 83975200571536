<div class="select-action-container d-flex align-items-center">
	<div class="selected-count d-flex align-items-center gap-8">
		<span class="count"
			>{{bulkSelected ? items?.meta?.total_count :data?.size}}</span
		>
		<span class="count-text"
			>{{data?.size === 1 ? (gridInfo?.title + " Selected") : (gridInfo?.title +
			"s Selected")}}</span
		>
	</div>
	<div class="seperator"></div>
	<div class="d-flex align-items-center gap-6">
		<label class="checkbox" for="bulkSelect">
			<input
				type="checkbox"
				name="checkbox"
				id="bulkSelect"
				(click)="selectAllFunc()"
				[checked]="bulkSelected"
			/>
			<div class="checkbox-section"><i class="icon icon-Check"></i></div>
		</label>
		<div class="bulk-select cursor-pointer" (click)="selectAllFunc()" translate>
			Select All
		</div>
	</div>
	<div class="seperator"></div>
	<div
		ngbDropdown
		class="d-inline-block position-static"
		display="dynamic"
		#bulkSelect="ngbDropdown"
	>
		<span id="dropdownBasic1" ngbDropdownToggle class="cursor-pointer"
			><span
				class="bulk-Delete cursor-pointer hover-state d-flex align-items-center gap-2"
				[ngClass]="{'activeAction':bulkSelect.isOpen()}"
				>Actions <i class="icon icon-Caret-down"></i
			></span>
		</span>

		<div
			ngbDropdownMenu
			aria-labelledby="dropdownBasic1"
			[ngClass]="{'d-none':!router.url.includes('scorecard') && !router.url.includes('share-of-search') && !router.url.includes('discover')}"
			*ngIf="!(col?.meta?.type === 'updateSegment')"
		>
			<ng-container *ngIf="gridInfo?.type?.includes('tracker')">
				<button class="dropdown-item" (click)="openModal(content,'deleteView')">
					<i class="icon icon-Trash"></i>
					<span
						>Delete {{bulkSelected ? items?.meta?.total_count :data?.size}}
						{{data.size===1?'Tracker':'Trackers'}}</span
					>
				</button>
				<button
					class="dropdown-item"
					(click)="openModal(harvesting,'stopHarvesting')"
				>
					<i class="icon icon-Pause"></i>
					<span>Pause {{data.size===1?'Tracker':'Trackers'}}</span>
				</button>
			</ng-container>
			<ng-container *ngIf="gridInfo?.type === 'all-product'">
				<button
					class="dropdown-item"
					(click)="openModal(bulkDiscard,'bulkDiscard')"
				>
					<i class="icon icon-Trash-alt"></i>
					<span>Discard {{data.size===1?'Product':'Products'}}</span>
				</button>
			</ng-container>
			<!-- <button class="dropdown-item" (click)="openModal(content,'deleteView')">
                <i class="icon icon-Trash"></i>
                <span>Delete {{bulkSelected ? items?.meta?.total_count :data?.size}} {{data.size===1?'Tracker':'Trackers'}}</span>
            </button>
            <button class="dropdown-item" (click)="openModal(harvesting,'stopHarvesting')">
                <i class="icon icon-Pause"></i>
                <span translate>Pause {{data.size===1?'Tracker':'Trackers'}}</span>
            </button> -->
		</div>
		<div
			ngbDropdownMenu
			aria-labelledby="dropdownBasic1"
			*ngIf="col?.meta?.type === 'updateSegment'"
		>
			<button class="dropdown-item" (click)="openStoreSegmentSelectionModal()">
				<i class="icon icon-Edit"></i>
				<span translate>Edit Segment</span>
			</button>
		</div>
	</div>
	<!-- <div class="bulk-Delete cursor-pointer hover-state d-flex align-items-center gap-2" (click)="openModal(content,'deleteView')" translate><i class="icon icon-Trash"></i>Delete All</div> -->
</div>
<ng-template #content let-modal>
	<div class="modal-header">
		<h4 class="modal-title" translate>
			Delete {{'Tracker' | plural: (data.size || bulkSelected)}}
		</h4>
	</div>
	<div class="modal-body">
		<i class="icon icon-Trash icon-highlight" ngbAutofocus></i>
		<p translate>
			Are you sure you want to delete
			<b>{{bulkSelected ? items?.meta?.total_count :data?.size}} </b>
			<span translate>{{'tracker' | plural: (data.size || bulkSelected)}} </span
			>?
		</p>
	</div>
	<div class="modal-footer">
		<button
			type="button"
			class="btn btn-secondary btn-lg"
			(click)="modal.close('Close click')"
			translate
		>
			Cancel
		</button>
		<button
			type="button"
			class="btn btn-primary btn-lg"
			(click)="[deleteFunc(), modal.close('Close click')]"
			translate
		>
			Yes, Delete
		</button>
	</div>
</ng-template>

<ng-template #harvesting let-modal>
	<div class="modal-header">
		<h4 class="modal-title" translate>
			Pause {{'Tracker' | plural: (data.size || bulkSelected)}}
		</h4>
	</div>
	<div class="modal-body">
		<i class="{{'icon icon-Pause icon-highlight'}}" ngbAutofocus></i>
		<p translate>
			<span translate>Are you sure you want to pause tracking for</span
			><span>
				selected {{'tracker' | plural: (data.size || bulkSelected)}}</span
			>
			?
		</p>
	</div>
	<div class="modal-footer">
		<button
			type="button"
			class="btn btn-secondary btn-lg"
			(click)="modal.close(true)"
			translate
		>
			Cancel
		</button>
		<button
			type="button"
			class="btn btn-primary btn-lg"
			(click)="[deactivateProfile()]"
			translate
		>
			Yes
		</button>
	</div>
</ng-template>

<ng-template #bulkDiscard let-modal>
	<div class="modal-header">
		<h4 class="modal-title" translate>
			Discard {{'Product' | plural: (data.size || bulkSelected)}}
		</h4>
	</div>
	<div class="modal-body">
		<i class="{{'icon icon-Trash-alt icon-highlight'}}" ngbAutofocus></i>
		<p class="mb-0" translate>
			<span translate>Are you sure you want to discard</span
			><span>
				selected {{'product' | plural: (data.size || bulkSelected)}}</span
			>
			?
		</p>
		<p class="exclude-title m-0" *ngIf="bulkExcludeInfo.isBulkExclude">
			Also do you want to add
			<b>{{bulkExcludeInfo.selectedExclude.join("/")}}</b> as
			<b>Exclude by {{bulkExcludeInfo.selectedExclude.join("/")}}</b> in
			respective tracker’s through which this products are tracked ?
		</p>

		<!-- Exclude Start -->
		<div
			class="d-flex gap-16 w-100 justify-content-center"
			*ngIf="bulkExcludeInfo.isBulkExclude"
		>
			<ng-container
				*ngFor="let item of ['store', 'brand', 'category', 'keyword']"
			>
				<section
					class="d-flex justify-content-center"
					[ngStyle]="{width: 100/bulkExcludeInfo.selectedExclude.length+'rem'}"
					*ngIf="bulkExclude[item].list.length"
				>
					<div class="exclude-container">
						<div class="checkbox-container more-item">
							<label class="checkbox" [for]="item+'_exclude_all'">
								<input
									type="checkbox"
									name="checkbox"
									[id]="item+'_exclude_all'"
									[checked]="bulkExclude[item].list.length===bulkExclude[item].selected.length"
									(change)="onExcludeChange($event, true, item, 'all')"
								/>
								<div class="checkbox-section">
									<i class="icon icon-Check"></i>
								</div>
								<span class="checkbox-data font-medium"
									>{{bulkExclude[item].title | translate}}</span
								>
							</label>
						</div>
						<div class="exclude-items d-flex flex-column">
							<div
								class="checkbox-container more-item"
								*ngFor="let data of bulkExclude[item].list"
							>
								<label class="checkbox" [for]="item+'_exclude'+data">
									<input
										type="checkbox"
										name="checkbox"
										[id]="item+'_exclude'+data"
										[checked]="bulkExclude[item].selected.includes(data)"
										(change)="onExcludeChange($event, false, item, data)"
									/>
									<div class="checkbox-section">
										<i class="icon icon-Check"></i>
									</div>
									<span class="checkbox-data">{{data}}</span>
								</label>
							</div>
						</div>
					</div>
				</section>
			</ng-container>
		</div>
		<!-- Exclude End -->
	</div>
	<div class="modal-footer">
		<button
			type="button"
			class="btn btn-secondary btn-lg"
			(click)="modal.close(true)"
			translate
		>
			Cancel
		</button>
		<button
			type="button"
			class="btn btn-primary btn-lg"
			(click)="[discardProducts()]"
			translate
		>
			Yes
		</button>
	</div>
</ng-template>
