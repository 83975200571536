<ng-container *ngIf="col?.method === 'navigationText'">
	<section class="d-flex align-items-center gap-2">
		<a
			*ngIf="item?.attributes?.product_count"
			[routerLink]="[commonFunctions?.redirectToScorecardInfo((item?.attributes?.profile_type==='product')?item?.attributes?.scorecard_id:item?.id,item?.attributes?.profile_type,true,'profile')?.params]"
			[queryParams]="commonFunctions?.redirectToScorecardInfo((item?.attributes?.profile_type==='product')?item?.attributes?.scorecard_id:item?.id,item?.attributes?.profile_type,true,'profile')?.queryParams"
			[target]="'_self'"
			class="navigationText on-hover-action ellipsis"
			[ngClass]="{'wid-15':col.meta?.class==='tracker-child-field'}"
			showTooltipOnTextOverflow
			[data]="commonFunctions.getMappedObject(item,col?.map)"
		>
		</a>
		<div
			*ngIf="!item?.attributes?.product_count"
			class="navigationText on-hover-action"
			showTooltipOnTextOverflow
			[data]="commonFunctions.getMappedObject(item,col?.map)"
			(click)="openModalForUntrackedProfile(item?.attributes?.profile_type)"
		></div>
		<a
			*ngIf="item?.attributes?.profile_type!=='keyword'"
			[href]="item?.attributes?.profile_val"
			target="_blank"
			class="navigate-link d-flex align-items-center"
			placement="{{commonFunctions.rtlStatus? 'left' : 'right'}}"
			ngbTooltip="{{('Go to Marketplace ' + (item?.attributes?.profile_type | titlecase)) | translate}}"
			tooltipClass="info-custom-tooltip"
			><i class="icon icon-Arrow-up-2 d-inline-block"></i
		></a>
	</section>
	<div
		*ngIf="item.attributes.is_brand_store"
		class="badgeView badge-light-grey font-medium brand-store"
		translate
	>
		Brand Store
	</div>
</ng-container>
<ng-container *ngIf="col?.method === 'defaultView'">
	<div
		class="{{col?.class}}"
		*ngIf="commonFunctions.getMappedObject(item,col?.map); else noData"
		showTooltipOnTextOverflow
		[data]="commonFunctions.getMappedObject(item,col?.map)"
	>
		{{commonFunctions.getMappedObject(item,col?.map)}}
	</div>
</ng-container>
<ng-container *ngIf="col?.method === 'dateFormat'">
	<div *ngIf="commonFunctions.getMappedObject(item,col?.map); else noData">
		{{commonFunctions.formatSimpleDate(commonFunctions.getMappedObject(item,col?.map),
		col?.meta?.dateFormat)}}
	</div>
</ng-container>
<ng-container *ngIf="col?.method === 'defaultCatagoryView'">
	<div
		*ngIf="commonFunctions.getMappedObject(item,col?.map); else noData"
		class="catNameTooltip"
		placement="right"
		ngbTooltip="{{item?.attributes?.category_chain | translate}}"
		tooltipClass="info-custom-tooltip"
	>
		{{commonFunctions.formatSimpleDate(commonFunctions.getMappedObject(item,col?.map))}}
	</div>
</ng-container>
<ng-container *ngIf="col?.method === 'logoFormat'">
	<div
		class="d-flex align-items-center gap-6"
		*ngIf="commonFunctions.getMappedObject(item,col?.map);else noData"
	>
		<app-marketplace-store-logo
			[storeName]="commonFunctions.getMappedObject(item,col?.map)"
		>
		</app-marketplace-store-logo>
		<div class="d-inline">
			{{commonFunctions.getMappedObject(item,col?.map)}}
		</div>
	</div>
</ng-container>
<ng-container *ngIf="col?.method === 'badgeView'">
	<div
		*ngIf="commonFunctions.getMappedObject(item,col?.map) || commonFunctions.getMappedObject(item,col?.map) === false"
		class="{{differentBadges[commonFunctions.getMappedObject(item,col?.map)]?.class}} badgeView"
		translate
	>
		{{differentBadges[commonFunctions.getMappedObject(item,col?.map)].name |
		translate}}
	</div>
	<div
		*ngIf="!commonFunctions.getMappedObject(item,col?.map) && commonFunctions.getMappedObject(item,col?.map) !== false"
	>
		-
	</div>
</ng-container>
<ng-container *ngIf="col?.method === 'booleanBadgeView'">
	<div
		*ngIf="commonFunctions.getMappedObject(item,col?.map) || commonFunctions.getMappedObject(item,col?.map) === false"
		class="{{booleanKeyObj[col?.meta?.type][commonFunctions.getMappedObject(item,col?.map)]?.class}} badgeView"
		translate
	>
		{{booleanKeyObj[col?.meta?.type][commonFunctions.getMappedObject(item,col?.map)].name
		| translate}}
	</div>
	<div
		*ngIf="!commonFunctions.getMappedObject(item,col?.map) && commonFunctions.getMappedObject(item,col?.map) !== false"
	>
		-
	</div>
</ng-container>
<ng-container *ngIf="col?.method === 'stringBadgeView'">
	<div
		*ngIf="commonFunctions.getMappedObject(item,col?.map); else noData"
		class="{{differentBadges[commonFunctions.getMappedObject(item,col?.map)]?.class}} badgeView"
		translate
	>
		{{differentBadges[commonFunctions.getMappedObject(item,col?.map)].name |
		translate}}
	</div>
</ng-container>
<ng-container *ngIf="col?.method === 'filterCriteriaView'">
	<app-common-tags-dropdown
		[item]="item"
		[map]="col?.map"
	></app-common-tags-dropdown>
</ng-container>
<ng-container *ngIf="col?.method === 'healthProfileName'">
	<div class="profileNameContent d-flex flex-column">
		<a
			class="name on-hover-action"
			[routerLink]="commonFunctions.redirectToScorecardInfo(item?.attributes?.store_id,'store',true)?.params"
			[target]="'_blank'"
			>{{commonFunctions.getMappedObject(item,col?.map?.name) ?
			commonFunctions.getMappedObject(item,col?.map?.name) : '-'}}</a
		>
		<div
			class="{{differentBadges[commonFunctions.getMappedObject(item,col?.map?.segment)]?.class}} badgeView mt-1"
			translate
		>
			{{differentBadges[commonFunctions.getMappedObject(item,col?.map?.segment)]?.name
			| translate}}
		</div>
	</div>
</ng-container>
<ng-container *ngIf="col?.method === 'healthMetric'">
	<ng-template #htmlContent>
		<div class="tooltip-container text-align-right-rtl">
			<div class="kpi-type" translate>{{kpi.name}}</div>
			<div class="healthData gap-11 d-flex align-items-center">
				<div class="productCount">
					<div class="count" translate>
						{{commonFunctions.getMappedObject(item,kpi?.map?.count)?(commonFunctions.getMappedObject(item,kpi?.map?.count)
						| numberSuffix :
						2):commonFunctions.getMappedObject(item,kpi?.map?.count)===0?0:'-'}}
					</div>
					<div class="title" translate>Products</div>
				</div>
				<div class="deltaValue">
					<div class="delta d-flex align-items-center">
						<span class="title" translate>WoW</span>
						<span
							class="value text-align-left-rtl"
							[ngClass]="{'negative-delta':commonFunctions.getMappedObject(item,kpi?.map?.wow)<0}"
							>{{commonFunctions.getMappedObject(item,kpi?.map?.wow)?commonFunctions.getMappedObject(item,kpi?.map?.wow)+'%':commonFunctions.getMappedObject(item,kpi?.map?.wow)===0?'0%':'-'}}</span
						>
					</div>
					<div class="delta d-flex align-items-center">
						<span class="title" translate>MoM</span>
						<span
							class="value text-align-left-rtl"
							[ngClass]="{'negative-delta':commonFunctions.getMappedObject(item,kpi?.map?.mom)<0}"
							>{{commonFunctions.getMappedObject(item,kpi?.map?.mom)?commonFunctions.getMappedObject(item,kpi?.map?.mom)+'%':commonFunctions.getMappedObject(item,kpi?.map?.mom)===0?'0%':'-'}}</span
						>
					</div>
				</div>
			</div>
		</div>
	</ng-template>
	<div
		class="metricValue d-inline-flex align-items-center justify-content-center cursor-pointer"
		[ngClass]="{'blurry-effect':isNAForRestriction}"
		[ngStyle]="commonFunctions.getViewHealthGridColor(kpi , commonFunctions.getMappedObject(item,kpi?.map?.value))"
		display="dynamic"
		[ngbTooltip]="!isNAForRestriction?htmlContent:null"
		tooltipClass="health-info-tooltip"
		(click)="dataTransferService.sendGraphSliceData({type:'health-grid',kpi:kpi,item:item,col:col})"
		translate
	>
		{{getValueToDisplay(item,col,kpi)}}
	</div>
</ng-container>
<ng-container
	*ngIf="col?.method === 'productImage' || col?.method === 'productImageWithDiscover'"
>
	<div class="scraped-product-start-container">
		<div
			class="image-container"
			ngbDropdown
			ngbDropdownToggle
			#drop="ngbDropdown"
			#dropRef
			(openChange)="onDropdownOpenClose(dropRef,$event)"
			(mouseover)="drop.open()"
			(mouseleave)="drop.close()"
			placement="top-end"
			*ngIf="!col?.meta?.showImage"
		>
			<img
				(error)="onImgError($event)"
				[src]="item?.attributes?.images?.length ? item.attributes.images[0] : '../../../../../assets/images/no-image.svg'"
				alt=""
			/>
			<div
				ngbDropdownMenu
				class="p-0 border-6 invisible"
				[id]="randomDropdownID"
			>
				<app-product-face [product]="item"></app-product-face>
			</div>
		</div>
		<div
			class="title-container d-flex"
			*ngIf="col?.method === 'productImageWithDiscover'"
		>
			<a
				[routerLink]="!col.meta?.isRedirect ? ['/scorecard/product/', item?.id] : null"
				class="title navigationText"
				[ngClass]="{'on-hover-action cursor-pointer' : !col.meta?.isRedirect }"
				(click)="setForMatches()"
				showTooltipOnTextOverflow
				[data]="item?.attributes?.name"
			>
			</a>
		</div>
		<div
			class="d-flex flex-column title-content"
			*ngIf="col?.method !== 'productImageWithDiscover'"
		>
			<div class="title-container d-flex align-items-center gap-2">
				<a
					[routerLink]="[commonFunctions?.redirectToScorecardInfo(item?.id,'product',true, col?.meta?.view)?.params]"
					[target]="col.meta?.navigateTarget?col.meta?.navigateTarget:'_blank'"
					class="title navigationText cursor-pointer on-hover-action"
					[queryParams]="commonFunctions?.redirectToScorecardInfo(item?.id,'product',true, col?.meta?.view)?.queryParams"
					showTooltipOnTextOverflow
					[data]="item?.attributes?.name"
				>
				</a>
			</div>
			<div
				*ngIf="col?.meta?.is_sponsored && item.attributes?.is_sponsored"
				class="badgeView badge-light-grey d-inline-block"
				translate
			>
				Sponsored
			</div>
		</div>
		<a
			*ngIf="col?.meta?.marketplaceLink"
			[href]="item?.attributes?.url || item?.attributes?.product_url"
			target="_blank"
			class="navigate-link d-flex align-items-center"
			placement="{{commonFunctions.rtlStatus? 'left' : 'right'}}"
			ngbTooltip="{{'Go to Marketplace Product' | translate}}"
			tooltipClass="info-custom-tooltip"
			><i class="icon icon-Arrow-up-2 d-inline-block"></i>
		</a>
	</div>
</ng-container>
<ng-container *ngIf="col?.method === 'priceView'">
	<div
		class="price {{col?.class}}"
		*ngIf="commonFunctions.isIntegerOrDefined(commonFunctions.getMappedObject(item,col?.map)); else noData"
		[ngClass]="{'d-flex flex-column align-items-end' : commonFunctions.getMappedObject(item,col?.meta?.noOfDays)}"
	>
		{{col.meta?.isOwnCurrencyAvailable?commonFunctions.getMappedObject(item,col.meta.currencyMap):globalCurrencyDetails.symbol}}{{commonFunctions.getMappedObject(item,col?.map)
		| numberSuffix: 2}}
		<span
			class="percent-change {{col?.meta?.class}}"
			*ngIf="commonFunctions.isIntegerOrDefined(commonFunctions.getMappedObject(item,col?.meta?.change))"
			[ngClass]="{'negative-delta':commonFunctions.getMappedObject(item,col?.meta?.change)<0,'positive-delta':commonFunctions.getMappedObject(item,col?.meta?.change)>0}"
			><i
				class="icon"
				[ngClass]="{'icon-Triangle-Down1':commonFunctions.getMappedObject(item,col?.meta?.change)<0,'icon-Triangle-Up1':commonFunctions.getMappedObject(item,col?.meta?.change)>0}"
			></i
			>{{commonFunctions.getMappedObject(item,col?.meta?.change)<0?(-commonFunctions.getMappedObject(item,col?.meta?.change)):commonFunctions.getMappedObject(item,col?.meta?.change)}}%
		</span>
		<span
			class="percent-change m-0 text-end {{col.meta?.class}}"
			*ngIf="col.meta?.showPercentage && commonFunctions.isIntegerOrDefined(commonFunctions.getMappedObject(item,col.meta?.percentage))"
		>
			({{commonFunctions.getMappedObject(item,col.meta?.percentage)}}%)</span
		>
		<span
			class="no-of-days"
			*ngIf="commonFunctions.getMappedObject(item,col?.map)!==null && commonFunctions.getMappedObject(item,col?.meta?.noOfDays)"
			>({{commonFunctions.getMappedObject(item,col?.meta?.noOfDays)}}
			{{commonFunctions.getMappedObject(item,col?.meta?.noOfDays) <=1 ? 'day':
			'days'}})
		</span>
	</div>
</ng-container>
<ng-container *ngIf="col?.method === 'priceRangeView'">
	<div
		class="price {{col?.class}}"
		*ngIf="commonFunctions.getMappedObject(item,col?.map); else noData"
	>
		{{globalCurrencyDetails.symbol}}{{commonFunctions.getMappedObject(item,col?.map)
		| numberSuffix: 2}} -
		{{globalCurrencyDetails.symbol}}{{commonFunctions.getMappedObject(item,col?.meta?.change)
		| numberSuffix: 2}}
	</div>
</ng-container>
<ng-container *ngIf="col?.method === 'downloadView'">
	<a (click)="downloadFile()" class="downloadView">
		<i class="icon icon-Download"></i>
	</a>
</ng-container>

<ng-container *ngIf="col?.method === 'parseDay'">
	<div
		*ngIf="commonFunctions.getMappedObject(item,col?.map)"
		class="badgeView badge-dark-pink d-inline-block"
		translate
	>
		{{commonFunctions.getMappedObject(item,col?.map) | numberSuffix : 2}} Days
	</div>
	<div *ngIf="!commonFunctions.getMappedObject(item,col?.map)">-</div>
</ng-container>
<ng-container *ngIf="col?.method === 'countView'">
	<ng-container
		*ngIf="col.name==='Quantity' && !(commonFunctions.isIntegerOrDefined(commonFunctions.getMappedObject(item,col.map)));else normalFlow"
	>
		<div class="{{col?.class}}">NA</div>
	</ng-container>
	<ng-template #normalFlow>
		<div
			(click)="onCountClick(col.clickable)"
			[ngClass]="{'on-hover-action':col.clickable}"
			class="{{col?.class}}"
			*ngIf="commonFunctions.getMappedObject(item,col?.map)!==null; else noData"
		>
			{{col?.prefix ? col?.prefix :
			''}}{{col.meta?.isPDP?(commonFunctions.getMappedObject(item,col?.map) |
			number):commonFunctions.getMappedObject(item,col?.map) | numberSuffix :
			2}}{{col?.suffix ? col?.suffix : ''}}
			<span
				class="percent-change"
				*ngIf="commonFunctions.getMappedObject(item,col?.meta?.change)"
				[ngClass]="{'negative-delta':commonFunctions.getMappedObject(item,col?.meta?.change)<0,'positive-delta':commonFunctions.getMappedObject(item,col?.meta?.change)>0}"
				><i
					class="icon"
					[ngClass]="{'icon-Triangle-Down1':commonFunctions.getMappedObject(item,col?.meta?.change)<0,'icon-Triangle-Up1':commonFunctions.getMappedObject(item,col?.meta?.change)>0}"
				></i
				>{{commonFunctions.getMappedObject(item,col?.meta?.change)<0?(-commonFunctions.getMappedObject(item,col?.meta?.change)):commonFunctions.getMappedObject(item,col?.meta?.change)}}%</span
			>
			<p
				class="percent-change m-0 text-end {{col.meta?.class}}"
				*ngIf="col.meta?.showPercentage && commonFunctions.getMappedObject(item,col.meta?.percentage)"
			>
				({{commonFunctions.getMappedObject(item,col.meta?.percentage)}}%)
			</p>
		</div>
	</ng-template>
</ng-container>
<ng-container *ngIf="col?.method === 'targetKPIView'">
	<div
		class="{{col?.class}}"
		*ngIf="commonFunctions.getMappedObject(item,col?.map)!==null"
	>
		{{item?.attributes?.operator}}{{commonFunctions.getMappedObject(item,col?.map)
		| numberSuffix : 2}}{{item?.attributes?.target_kpi_value_2 ?
		item?.attributes?.operator_2 : ''}}{{item?.attributes?.target_kpi_value_2 |
		numberSuffix : 2}}
	</div>
</ng-container>
<ng-container *ngIf="col?.method === 'storeView'">
	<section class="d-flex align-items-center gap-2">
		<div
			*ngIf="item.attributes?.store_id || col.meta?.shouldRedirectToScorecard"
		>
			<a
				[routerLink]="[commonFunctions?.redirectToScorecardInfo((moduleType.name==='store-scorecard' || col.meta?.type === 'store' || moduleType.name==='store')?item?.id:item.attributes?.store_id,'store',true)?.params]"
				[target]="col.meta?.navigateTarget?col.meta?.navigateTarget:'_blank'"
				class="{{col?.class}} on-hover-action"
				*ngIf="commonFunctions.getMappedObject(item,col?.map); else noData"
				showTooltipOnTextOverflow
				[data]="commonFunctions.getMappedObject(item,col?.map)"
				[queryParams]="commonFunctions?.redirectToScorecardInfo(item?.id,'store',true, col?.meta?.view)?.queryParams"
			>
			</a>
		</div>
		<div
			*ngIf="!item.attributes?.store_id && !col.meta?.shouldRedirectToScorecard"
		>
			<span *ngIf="commonFunctions.getMappedObject(item,col?.map); else noData"
				>{{commonFunctions.getMappedObject(item,col?.map)}}</span
			>
		</div>
		<a
			*ngIf="col?.meta?.marketplaceLink && item?.attributes?.store_url"
			[href]="item?.attributes?.store_url"
			target="_blank"
			class="navigate-link d-flex align-items-center"
			placement="{{commonFunctions.rtlStatus? 'left' : 'right'}}"
			ngbTooltip="{{'Go to Marketplace Store' | translate}}"
			tooltipClass="info-custom-tooltip"
			><i class="icon icon-Arrow-up-2 d-inline-block"></i>
		</a>
	</section>
	<a
		*ngIf="item.attributes.official_store"
		class="badgeView badge-dark-pink cursor-pointer officialStore ellipsis"
		target="_blank"
		[href]="item.attributes.official_store_url"
		showTooltipOnTextOverflow
		[data]="item.attributes.official_store"
	></a>
</ng-container>
<ng-container *ngIf="col?.method === 'productView'">
	<a
		[routerLink]="[commonFunctions?.redirectToScorecardInfo(item?.attributes?.product_id,'product',true)?.params]"
		[target]="col.meta?.navigateTarget?col.meta?.navigateTarget:'_blank'"
		class="{{col?.class}} on-hover-action"
		*ngIf="commonFunctions.getMappedObject(item,col?.map); else noData"
		showTooltipOnTextOverflow
		[data]="commonFunctions.getMappedObject(item,col?.map)"
	>
	</a>
</ng-container>
<ng-container *ngIf="col?.method === 'profileView'">
	<a
		[routerLink]="commonFunctions.redirectToScorecardInfo(commonFunctions.getMappedObject(item, 'id'),col?.meta?.type,true)?.params"
		[target]="col.meta?.navigateTarget?col.meta?.navigateTarget:'_blank'"
		class="navigationText on-hover-action"
		showTooltipOnTextOverflow
		[data]="commonFunctions.getMappedObject(item,col?.map)"
		[queryParams]="commonFunctions?.redirectToScorecardInfo(item?.id,col?.meta?.type,true,col?.meta?.view)?.queryParams"
	>
	</a>
</ng-container>
<ng-container *ngIf="col?.method === 'variantView'">
	<div
		*ngIf="commonFunctions.getMappedObject(item,col?.map); else noData"
		class="badgeView badge-dark-orange cursor-pointer {{col?.class}} varint-link"
		(click)="dataTransferService.sendGraphSliceData({type:moduleType?.name,col:col,item:item})"
		translate
	>
		In {{commonFunctions.getMappedObject(item,col?.map) | numberSuffix : 2}}
		variants
	</div>
</ng-container>
<ng-container *ngIf="col?.method === 'clickableUnitWithString'">
	<ng-container
		*ngIf="commonFunctions.getMappedObject(item,col?.map); else noData"
	>
		<div ngbDropdown display="dynamic" class="harmonised-raw-list">
			<span
				class="badgeView cursor-pointer {{col?.class}}"
				[ngClass]="commonFunctions.getMappedObject(item,col?.map).length?'badge-dark-blue':'badge-paused'"
				ngbDropdownToggle
				translate
				(click)="mappedSelected=[]"
				>{{commonFunctions.getMappedObject(item,col?.map).length | numberSuffix
				: 2}}</span
			>
			&nbsp;{{getRawHeaderName(true, commonFunctions.getMappedObject(item,
			'attributes.mapped_count')) | titlecase}}
			<div
				ngbDropdownMenu
				aria-labelledby="dropdownBasic1"
				class="variantDropdownList"
				*ngIf="commonFunctions.getMappedObject(item,col?.map).length"
			>
				<div
					class="font-medium title mb-2 d-flex justify-content-between align-items-center"
				>
					<span>Raw {{getRawHeaderName(false)}}</span>
					<button
						class="btn btn-primary btn-sm"
						(click)="openHarmonisedUnmapModal(item.id, mappedSelected, item)"
						[disabled]="mappedSelected.length===0"
						*ngIf="!col?.meta?.hideUnmapAllbtn || permissions.write_access"
					>
						Unmap {{getRawHeaderName(true, mappedSelected.length) | titlecase}}
					</button>
				</div>
				<section class="d-flex flex-column variant-items">
					<div
						class="checkbox-container more-item"
						*ngIf="!col?.meta?.hideUnmapAllbtn || permissions.write_access"
					>
						<label class="checkbox" [for]="'select_all_'+item.id">
							<input
								type="checkbox"
								name="checkbox"
								[id]="'select_all_'+item.id"
								[checked]="mappedSelected.length===commonFunctions.getMappedObject(item,col?.map).length"
								(change)="onHarmonisedMappedCheckUncheck($event, null, commonFunctions.getMappedObject(item,col?.map))"
							/>
							<div class="checkbox-section">
								<i class="icon icon-Check"></i>
							</div>
							<span
								class="fil-text user-select-none font-medium select-all-text"
								>{{('Select all '+getRawHeaderName(false)) | translate}}</span
							>
						</label>
					</div>
					<div
						class="checkbox-container more-item"
						*ngFor="let mapped of commonFunctions.getMappedObject(item,col?.map)"
					>
						<label class="checkbox" [for]="item.id+'_'+mapped.id">
							<ng-container
								*ngIf="!col?.meta?.hideUnmapAllbtn || permissions.write_access"
							>
								<input
									type="checkbox"
									name="checkbox"
									[id]="item.id+'_'+mapped.id"
									[checked]="mappedSelected.includes(mapped.id)"
									(change)="onHarmonisedMappedCheckUncheck($event, mapped, commonFunctions.getMappedObject(item,col?.map))"
								/>
								<div class="checkbox-section">
									<i class="icon icon-Check"></i>
								</div>
							</ng-container>
							<span class="checkbox-data">{{mapped.name}}</span>
						</label>
					</div>
				</section>
			</div>
		</div>
	</ng-container>
</ng-container>
<ng-container *ngIf="col?.method === 'unitPrice'">
	<div class="unitPrice {{col?.class}}">
		<span
			class="price"
			*ngIf="commonFunctions.getMappedObject(item,col?.map); else noData"
		>
			{{globalCurrencyDetails.symbol}}{{commonFunctions.getMappedObject(item,col?.map)
			| numberSuffix: 2}}
		</span>
		<span
			class="priceChange d-block"
			*ngIf="commonFunctions.getMappedObject(item,col?.map) && item?.attributes?.difference"
			>({{moduleType.name==='overpriced-products'?'+':'-'}}{{item?.attributes?.difference}}%)</span
		>
	</div>
</ng-container>
<ng-container *ngIf="col?.method === 'discountView'">
	<div
		class="badgeView discountView badge-light-yellow {{col?.class}}"
		*ngIf="commonFunctions.getMappedObject(item,col?.map)!==null; else noData"
		translate
	>
		{{commonFunctions.getMappedObject(item,col?.map) | number}}% OFF
	</div>
	<span
		class="percent-change"
		*ngIf="commonFunctions.isIntegerOrDefined(commonFunctions.getMappedObject(item,col?.map)) && commonFunctions.isIntegerOrDefined(commonFunctions.getMappedObject(item,col?.meta?.change))"
		[ngClass]="{'negative-delta':commonFunctions.getMappedObject(item,col?.meta?.change)<0,'positive-delta':commonFunctions.getMappedObject(item,col?.meta?.change)>0}"
		><i
			class="icon"
			[ngClass]="{'icon-Triangle-Down1':commonFunctions.getMappedObject(item,col?.meta?.change)<0,'icon-Triangle-Up1':commonFunctions.getMappedObject(item,col?.meta?.change)>0}"
		></i
		>{{commonFunctions.getMappedObject(item,col?.meta?.change)<0?(-commonFunctions.getMappedObject(item,col?.meta?.change)):commonFunctions.getMappedObject(item,col?.meta?.change)}}%</span
	>
	<div
		class="no-of-days"
		*ngIf="commonFunctions.getMappedObject(item,col?.map)!==null && commonFunctions.getMappedObject(item,col?.meta?.noOfDays)"
	>
		({{commonFunctions.getMappedObject(item,col?.meta?.noOfDays)}}
		{{commonFunctions.getMappedObject(item,col?.meta?.noOfDays) <= 1 ? 'day':
		'days'}})
	</div>
</ng-container>
<ng-container *ngIf="col?.method === 'ratingView'">
	<ng-template #starLabel>
		<span class="star">&#x02605;</span>
	</ng-template>
	<div
		class="reviews"
		*ngIf="commonFunctions.getMappedObject(item,col?.map)!==null && item?.attributes?.reviews >= 0"
	>
		{{item?.attributes?.reviews | numberSuffix: 2}} {{item?.attributes?.reviews
		<=1 ? 'Review' : 'Reviews'}}
	</div>
	<div
		class="ratingLabel"
		[ngClass]="commonFunctions.getStarStatus(commonFunctions.getMappedObject(item,col?.map))"
		*ngIf="commonFunctions.getMappedObject(item,col?.map)!==null; else noData"
	>
		{{commonFunctions.getMappedObject(item,col?.map)}}<ngb-rating
			[max]="1"
			[starTemplate]="starLabel"
			[rate]="1"
		>
		</ngb-rating>
	</div>
</ng-container>
<ng-container *ngIf="col?.method === 'trackedByView'">
	<ng-container
		*ngIf="commonFunctions.getMappedObject(item,col?.map)?.length;else noData"
	>
		<div class="tracked-by-parent d-flex" ngbDropdown display="dynamic">
			<div class="type-badge">
				{{commonFunctions.getMappedObject(item,col?.map)[0].profile_type.split('')[0]|uppercase}}:
			</div>
			<div
				class="profile-name text-truncate"
				[ngClass]="{'single-profile-name-width':commonFunctions.getMappedObject(item,col?.map).length===1}"
				showTooltipOnTextOverflow
				[data]="commonFunctions.getMappedObject(item,col?.map)[0].profile_name"
			></div>
			<span
				class="link cursor-pointer ms-auto"
				id="dropdownBasic1"
				ngbDropdownToggle
				*ngIf="commonFunctions.getMappedObject(item,col?.map).length>1"
				>+ {{commonFunctions.getMappedObject(item,col?.map).length - 1}}</span
			>
			<div
				ngbDropdownMenu
				aria-labelledby="dropdownBasic1"
				class="commonDropdown customTooltipPositionContainer"
			>
				<ng-container
					*ngFor="let tag of commonFunctions.getMappedObject(item,col?.map).slice(1);let last = last"
				>
					<div class="tracked-by-parent d-flex" [ngClass]="{'mb-1':!last}">
						<div class="type-badge">
							{{tag.profile_type.split('')[0]|uppercase}}:
						</div>
						<div
							class="profile-name text-truncate dropdown-profile-name-width customTooltipPosition"
							showTooltipOnTextOverflow
							[data]="tag.profile_name"
						></div>
					</div>
				</ng-container>
			</div>
		</div>
	</ng-container>
</ng-container>
<ng-container *ngIf="col?.method === 'parseSalesQty'">
	<div
		class="price {{col?.class}}"
		*ngIf="commonFunctions.getMappedObject(item,col?.map); else noSalesPrice"
	>
		<div class="m-0 text-end">
			{{globalCurrencyDetails.symbol}}{{commonFunctions.getMappedObject(item,col?.map)
			| numberSuffix : 2}}
		</div>
		<p
			class="percent-change m-0 text-end {{col?.meta?.class}}"
			*ngIf="commonFunctions.getMappedObject(item,col?.meta?.change)"
			[ngClass]="{'negative-delta':(col?.name === 'Current Sales' && commonFunctions.getMappedObject(item,col?.meta?.change) < item?.attributes?.sales_old),'positive-delta':(col?.name === 'Current Sales' && commonFunctions.getMappedObject(item,col?.meta?.change) > item?.attributes?.sales_old)}"
		>
			({{commonFunctions.getMappedObject(item,col?.meta?.change)}}
			{{commonFunctions.getMappedObject(item,col?.meta?.change) > 1 ? 'units' :
			'unit'}})
		</p>
	</div>
</ng-container>
<ng-container *ngIf="col?.method === 'parseDirectValue'">
	<div>
		<div showTooltipOnTextOverflow [data]="col?.map"></div>
	</div>
</ng-container>
<ng-container *ngIf="col.method === 'aiServices'">
	<ng-container
		*ngIf="commonFunctions.getMappedObject(item,col.map); else noData"
	>
		<div
			*ngFor="let service of commonFunctions.getMappedObject(item,col.map)"
			class="d-flex gap-8 align-items-center mt-2"
		>
			<div
				class="badgeView badge-light-yellow {{col?.class}}"
				translate
				display="dynamic"
				[ngbTooltip]="AI_SERVICES[service] | translate"
			>
				{{service | uppercase}}
			</div>
			<div>{{AI_SERVICES[service]}}</div>
		</div>
	</ng-container>
</ng-container>
<ng-container *ngIf="col?.method === 'harmonisationNavigation'">
	<section class="d-flex align-items-center gap-2">
		<a
			*ngIf="item?.attributes?.mapped_count; else textWithoutNavigation"
			[routerLink]="[commonFunctions?.redirectToScorecardInfo(item?.id,col?.meta?.type,true)?.params]"
			[target]="'_self'"
			class="navigationText on-hover-action ellipsis"
			[ngClass]="{'wid-15':col.meta?.class==='tracker-child-field'}"
			showTooltipOnTextOverflow
			[data]="commonFunctions.getMappedObject(item,col?.map)"
			[queryParams]="commonFunctions?.redirectToScorecardInfo(item?.id,'store',true, col?.meta?.view)?.queryParams"
		>
		</a>
		<ng-template #textWithoutNavigation>
			<div>
				<span>{{commonFunctions.getMappedObject(item,col?.map)}}</span>
			</div>
		</ng-template>
	</section>
</ng-container>

<ng-container *ngIf="col?.method === 'pricewithDiscountView'">
	<div class="d-flex flex-column align-items-end">
		<div
			class="price {{col?.class}}"
			*ngIf="commonFunctions.getMappedObject(item,col?.map); else noData"
		>
			{{col.meta?.isOwnCurrencyAvailable?commonFunctions.getMappedObject(item,col.meta.currencyMap):globalCurrencyDetails.symbol}}{{commonFunctions.getMappedObject(item,col?.map)
			| numberSuffix: 2}}
		</div>
		<div
			class="badgeView discountView badge-light-yellow {{col?.class}} mt-1"
			*ngIf="commonFunctions.getMappedObject(item,col?.map)!==null; else noData"
			translate
		>
			{{commonFunctions.getMappedObject(item,'attributes.min_discount') |
			number}}% OFF
		</div>
	</div>
</ng-container>
<ng-container *ngIf="col?.method === 'pricewithDiscountNewView'">
	<div class="d-flex flex-column align-items-end">
		<div
			class="price {{col?.class}}"
			*ngIf="commonFunctions.getMappedObject(item,col?.map); else noData"
		>
			{{col.meta?.isOwnCurrencyAvailable?commonFunctions.getMappedObject(item,col.meta.currencyMap):globalCurrencyDetails.symbol}}{{commonFunctions.getMappedObject(item,col?.map)
			| numberSuffix: 2}}
		</div>
		<div
			class="badgeView discountView badge-light-yellow {{col?.class}} mt-1"
			*ngIf="commonFunctions.getMappedObject(item,'attributes.discount_new')!==null; else noData"
			translate
		>
			{{commonFunctions.getMappedObject(item,'attributes.discount_new') |
			number}}% OFF
		</div>
	</div>
</ng-container>
<ng-container *ngIf="col?.method === 'storewithSegmentView'">
	<section class="d-flex flex-row">
		<div>
			<a
				[routerLink]="[commonFunctions?.redirectToScorecardInfo((moduleType.name==='store-scorecard' || col.meta?.type === 'store' || moduleType.name==='store')?item?.id:item.attributes?.store_id,'store',true)?.params]"
				[target]="col.meta?.navigateTarget?col.meta?.navigateTarget:'_blank'"
				class="{{col?.class}} on-hover-action"
				*ngIf="commonFunctions.getMappedObject(item,col?.map); else noData"
				showTooltipOnTextOverflow
				[data]="commonFunctions.getMappedObject(item,col?.map)"
				[queryParams]="commonFunctions?.redirectToScorecardInfo(item?.id,'store',true, col?.meta?.view)?.queryParams"
				id="storeTooltipView"
			>
			</a>
			<div
				*ngIf="commonFunctions.getMappedObject(item,'attributes.segment') || commonFunctions.getMappedObject(item,'attributes.segment') === false"
				class="{{differentBadges[commonFunctions.getMappedObject(item,'attributes.segment')]?.class}} badgeView"
				translate
			>
				{{differentBadges[commonFunctions.getMappedObject(item,'attributes.segment')].name
				| translate}}
			</div>
			<div
				*ngIf="!commonFunctions.getMappedObject(item, 'attributes.segment') && commonFunctions.getMappedObject(item,'attributes.segment') !== false"
			>
				-
			</div>
		</div>
		<div>
			<a
				*ngIf="col?.meta?.marketplaceLink && item?.attributes?.store_url"
				[href]="item?.attributes?.store_url"
				target="_blank"
				class="navigate-link d-flex align-items-center"
				ngbTooltip="{{'Go to Marketplace Store' | translate}}"
				tooltipClass="info-custom-tooltip"
				display="dynamic"
				><i class="icon icon-Arrow-up-2 d-inline-block"></i>
			</a>
		</div>
	</section>
</ng-container>
<ng-container *ngIf="col?.method === 'goToDiscover'">
	<div
		class="discover-navigation cursor-pointer"
		(click)="navigateToDiscover(col?.meta?.isStore ? 'store' : 'product',item.id, col?.meta?.profileId)"
	>
		<i
			class="icon icon-Discover"
			ngbTooltip="{{'Discover' | translate}}"
			tooltipClass="info-custom-tooltip"
		></i>
	</div>
</ng-container>
<ng-container *ngIf="col?.method === 'percentageView'">
	<div
		class="price {{col?.class}}"
		*ngIf="commonFunctions.getMappedObject(item,col?.map); else noData"
	>
		{{commonFunctions.getMappedObject(item,col?.map) | numberSuffix: 2}}%
	</div>
</ng-container>

<ng-container *ngIf="col?.method === 'buzzwordView'">
	<span
		class="buzzword-list"
		[ngClass]="{'cursor-pointer active': commonFunctions.getMappedObject(item,col?.map).length, 'cursor-not-allowed disabled': !commonFunctions.getMappedObject(item,col?.map).length}"
		(click)="getListOfProducts(commonFunctions.getMappedObject(item,col?.map), item.id, item.attributes?.name)"
	>
		{{commonFunctions.getMappedObject(item,col?.map).length}} Buzzwords
	</span>
</ng-container>

<ng-container *ngIf="col?.method === 'sentimentAnalysisView'">
	<div class="sentiment-info d-flex gap-8">
		<section
			class="item d-flex align-items-center"
			*ngFor="let item of getArrayEle(commonFunctions.getMappedObject(item,col?.map))"
		>
			<span
				class="color d-inline-block"
				[ngStyle]="{'background-color':item.color}"
			></span>
			<span class="text">{{item.value}}%</span>
		</section>
	</div>
</ng-container>

<ng-container *ngIf="col?.method === 'contentScoreView'">
	<div
		class="{{col?.class}}"
		*ngIf="commonFunctions?.isIntegerOrDefined(commonFunctions.getMappedObject(item,col?.map)); else noData"
	>
		{{commonFunctions.getMappedObject(item,col?.map)}}%
	</div>
</ng-container>

<ng-container *ngIf="col?.method === 'priceNpromotionView'">
	<div class="d-flex align-items-center justify-content-end gap-16">
		<div
			class="price {{col?.class}} m-35"
			*ngIf="commonFunctions.getMappedObject(item,col?.map) >= 0; else noData"
		>
			{{col.meta?.isOwnCurrencyAvailable?commonFunctions.getMappedObject(item,col.meta.currencyMap):globalCurrencyDetails.symbol}}{{commonFunctions.getMappedObject(item,col?.map)
			| numberSuffix: 2}}
		</div>
		<div
			class="badgeView discountView badge-light-yellow {{col?.class}} mt-1"
			*ngIf="commonFunctions.getMappedObject(item,col?.map)!==null; else noData"
			translate
		>
			{{commonFunctions.getMappedObject(item,'attributes.avg_discount') |
			number}}% OFF
		</div>
	</div>
</ng-container>

<ng-container *ngIf="col?.method === 'countryView'">
	<div
		class="price {{col?.class}}"
		*ngIf="commonFunctions.getMappedObject(item,col?.map); else noData"
	>
		{{commonFunctions.getMappedObject(item,col?.map)}}
	</div>
</ng-container>

<!-- Segments With Edit Segment Option -->
<ng-container *ngIf="col?.method === 'editSegment'">
	<section class="d-flex gap-8 align-items-center justify-content-between">
		<div
			*ngIf="commonFunctions.getMappedObject(item,col?.map) || commonFunctions.getMappedObject(item,col?.map) === false"
			class="{{differentBadges[commonFunctions.getMappedObject(item,col?.map)]?.class}} badgeView"
			translate
		>
			{{differentBadges[commonFunctions.getMappedObject(item,col?.map)].name |
			translate}}
		</div>
		<div
			class="edit-segment d-flex align-items-center"
			(click)="openStoreSegmentSelectionModal()"
		>
			<i class="icon icon-Edit"></i><span>Edit</span>
		</div>
	</section>
	<div
		*ngIf="!commonFunctions.getMappedObject(item,col?.map) && commonFunctions.getMappedObject(item,col?.map) !== false"
	>
		-
	</div>
</ng-container>
<!-- Code Ends Here -->

<!-- Add Harmonisation button for Brand Scorecard -->
<ng-container *ngIf="col?.method === 'addHarmonisation'">
	<button
		class="harmonised-button dropdown-item"
		ngbtooltip="Add new harmonised brand "
		tooltipclass="info-custom-tooltip"
		(click)="openAddHarmonisationModal('brand',item.attributes?.name,true)"
	>
		<i class="icon icon-Add"></i>
		<span translate>Add as a new harmonised brand</span>
	</button>
</ng-container>
<!-- Code ends here -->

<!-- No Brand with Harmonisation button -->
<ng-container *ngIf="col?.method === 'brandWithHarmonisation'">
	<div class="d-flex align-items-center gap-8">
		<div
			class="{{col?.class}}"
			*ngIf="commonFunctions.getMappedObject(item,col?.map); else noData"
			showTooltipOnTextOverflow
			[data]="commonFunctions.getMappedObject(item,col?.map)"
		>
			{{commonFunctions.getMappedObject(item,col?.map)}}
		</div>
		<div
			class="harmonised-button dropdown-item plusIcon"
			ngbtooltip="'Add as a new harmonised brand' | translate"
			tooltipclass="info-custom-tooltip"
			(click)="openAddHarmonisationModal('brand',item.attributes?.name ,false)"
			*ngIf="this.checkIfNoBrand(commonFunctions.getMappedObject(item,col?.map))"
			display="dynamic"
		>
			<i class="icon icon-Add"></i>
		</div>
	</div>
</ng-container>
<!-- code ends here -->
<ng-container *ngIf="col?.method === 'variantBadgeView'">
	<div class="d-flex align-items-center justify-content-end gap-16">
		<div
			class="badgeView discountView badge-light-yellow {{col?.class}} mt-1"
			*ngIf="commonFunctions.getMappedObject(item,col?.map)!==null; else noData"
			translate
		>
			{{commonFunctions.getMappedObject(item,col?.map) | numberSuffix: 2}}
		</div>
	</div>
</ng-container>

<!-- Mapped to Harmonisation -->
<ng-container *ngIf="col?.method === 'mappedToHarmonisation'">
	<div
		class="d-flex align-items-center mapping cursor-pointer gap-8"
		*ngIf="!commonFunctions.getMappedObject(item,col?.map)"
		(click)="onMapping()"
	>
		<i
			class="icon icon-Shuffle cursor-pointer"
			ngbtooltip="'Brand Mapping' | translate"
			tooltipclass="info-custom-tooltip"
		></i>
		<span translate>Unmapped</span>
	</div>
	<div *ngIf="commonFunctions.getMappedObject(item,col?.map)">
		{{commonFunctions.getMappedObject(item,col?.map)[0].name}}
	</div>
</ng-container>
<!-- code ends here -->

<!-- Details Page Navigation for Harmonised products -->
<ng-container *ngIf="col?.method === 'harmonisedNameView'">
	<ng-container
		*ngIf="col.meta?.isMultipleHarmonised;else singleHarmonisedName"
	>
		<div class="d-flex align-items-center gap-2">
			<ng-container
				*ngIf="commonFunctions.getMappedObject(item,col?.map).length;else noData"
			>
				<ng-container
					*ngFor="let harmonisedData of commonFunctions.getMappedObject(item,col?.map).slice(0,1)"
				>
					<a
						[routerLink]="[commonFunctions?.redirectToHarmonisedView(harmonisedData.id,'product',true)?.params]"
						[target]="'_blank'"
						class="navigationText on-hover-action ellipsis harmonised-name-view"
						[ngClass]="{'wid-15':col.meta?.class==='tracker-child-field'}"
						showTooltipOnTextOverflow
						[data]="harmonisedData.name"
						[queryParams]="commonFunctions?.redirectToHarmonisedView(harmonisedData.id,'product',true,'harmonised')?.queryParams"
					>
					</a>
				</ng-container>
				<div
					ngbDropdown
					class="d-inline-block position-static"
					display="dynamic"
				>
					<span id="dropdownBasic1" ngbDropdownToggle class="cursor-pointer"
						><span class="legends-list w-100"
							>{{commonFunctions.getMappedObject(item,col?.map).length > 1 ?
							('+'+ (commonFunctions.getMappedObject(item,col?.map).length-1)) :
							""}}</span
						>
					</span>
					<div
						ngbDropdownMenu
						aria-labelledby="dropdownBasic1"
						class="commonDropdown"
					>
						<div
							*ngFor="let harmonisedData of commonFunctions.getMappedObject(item,col?.map).slice(1,commonFunctions.getMappedObject(item,col?.map).length); let i = index"
							class="pb-1"
						>
							<a
								[routerLink]="[commonFunctions?.redirectToHarmonisedView(harmonisedData.id,'product',true)?.params]"
								[target]="'_blank'"
								class="navigationText on-hover-action ellipsis harmonised-name-view"
								showTooltipOnTextOverflow
								[data]="harmonisedData.name"
								[queryParams]="commonFunctions?.redirectToHarmonisedView(harmonisedData.id,'product',true,'harmonised')?.queryParams"
							>
							</a>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</ng-container>
	<ng-template #singleHarmonisedName>
		<div class="d-flex align-items-center gap-2">
			<a
				[routerLink]="[commonFunctions?.redirectToHarmonisedView(item.attributes.harmonised_product_id?item.attributes.harmonised_product_id:commonFunctions.getMappedObject(item,col.meta.harmonisedIdKey),col.meta.harmonisedType?col.meta.harmonisedType:'product',true)?.params]"
				[target]="'_blank'"
				class="navigationText on-hover-action ellipsis harmonised-name-view"
				[ngClass]="{'wid-15':col.meta?.class==='tracker-child-field'}"
				showTooltipOnTextOverflow
				[data]="commonFunctions.getMappedObject(item,col?.map)"
				[queryParams]="commonFunctions?.redirectToHarmonisedView(item.attributes.harmonised_product_id?item.attributes.harmonised_product_id:commonFunctions.getMappedObject(item,col.meta.harmonisedIdKey),col.meta.harmonisedType?col.meta.harmonisedType:'product',true,'harmonised')?.queryParams"
			>
			</a>
		</div>
	</ng-template>
</ng-container>
<!-- code ends here -->

<ng-container *ngIf="col?.method === 'variant-data'">
	<div
		*ngIf="commonFunctions.getMappedObject(item,col?.map)!==null; else noData"
		class="variants-count cursor-pointer"
		(click)="commonFunctions.getMappedObject(item,col?.map) && getVariantList(variantMapping, item.id)"
		[ngClass]="{'no-variant': !commonFunctions.getMappedObject(item,col?.map)}"
	>
		In {{item.attributes.variant_count}}
		{{item.attributes.variant_count>1?'Variants':'Variant'}}
	</div>
</ng-container>

<ng-container *ngIf="col?.method === 'booleanYesNoBadge'">
	<div
		class="{{col.meta.badgeType[commonFunctions.getMappedObject(item,col?.map)]}} badgeView"
		translate
	>
		{{col.meta.booleanMapping[commonFunctions.getMappedObject(item,col?.map)] |
		translate}}
	</div>
</ng-container>
<ng-container *ngIf="col.method === 'multiplePriceView'">
	<div>
		<div ngbDropdown class="d-inline-block position-static" display="dynamic">
			<span
				*ngIf="commonFunctions.getMappedObject(item,col?.map).length;else noData"
				>{{globalCurrencyDetails.symbol}}{{commonFunctions.getMappedObject(item,col?.map)[0]}}</span
			>
			<span
				id="dropdownBasic1"
				ngbDropdownToggle
				class="cursor-pointer"
				*ngIf="commonFunctions.getMappedObject(item,col?.map).length > 1"
				><span class="legends-list"
					>&nbsp;+{{commonFunctions.getMappedObject(item,col?.map).length-1}}</span
				>
			</span>
			<div
				ngbDropdownMenu
				aria-labelledby="dropdownBasic1"
				class="commonDropdown"
			>
				<div
					class="info-text"
					*ngFor="let price of commonFunctions.getMappedObject(item,col?.map).slice(1,commonFunctions.getMappedObject(item,col?.map).length); let i = index"
				>
					<span class="text">{{globalCurrencyDetails.symbol}}{{price}}</span>
				</div>
			</div>
		</div>
	</div>
</ng-container>
<ng-container *ngIf="col?.method === 'trendIconView'">
	<i
		class="icon icon-Daily-Store-Trend trend-icon cursor-pointer"
		(click)="onTrendAction()"
	></i>
</ng-container>
<!-- code ends here -->

<ng-container *ngIf="col?.method === 'multipleLabelView'">
	<div class="d-flex align-items-center gap-2">
		<ng-container
			*ngIf="commonFunctions.getMappedObject(item,col?.map).length;else noData"
		>
			<ng-container
				*ngFor="let string of commonFunctions.getMappedObject(item,col?.map).slice(0,1)"
			>
				<a class="ellipsis label" showTooltipOnTextOverflow [data]="string">
				</a>
			</ng-container>
			<div ngbDropdown class="d-inline-block position-static" display="dynamic">
				<span id="dropdownBasic1" ngbDropdownToggle
					><span class="legends-list w-100"
						>{{commonFunctions.getMappedObject(item,col?.map).length > 1 ? ('+'+
						(commonFunctions.getMappedObject(item,col?.map).length-1)) :
						""}}</span
					>
				</span>
				<div
					ngbDropdownMenu
					aria-labelledby="dropdownBasic1"
					class="commonDropdown"
				>
					<div
						*ngFor="let string of commonFunctions.getMappedObject(item,col?.map).slice(1,commonFunctions.getMappedObject(item,col?.map).length); let i = index"
						class="pb-1"
					>
						<a class="ellipsis label" showTooltipOnTextOverflow [data]="string">
						</a>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
</ng-container>

<ng-template #noData>
	<div>-</div>
</ng-template>

<ng-template #noSalesPrice>
	<div>0</div>
</ng-template>

<div *ngIf="isMappingPanelOpen">
	<div class="custom-right-panel">
		<div class="overlay"></div>
		<div class="right-panel">
			<app-harmonisation-mapping
				[harmonisationType]="'brand'"
				(closePanel)="onRightPanelEmit($event)"
				[itemDetails]="item"
			></app-harmonisation-mapping>
		</div>
	</div>
</div>

<ng-template #variantMapping let-modal>
	<div class="modal-header mb-3" ngbAutoFocus>
		<h4 class="modal-title" translate>Product variants</h4>
	</div>
	<div class="modal-body">
		<ng-container
			*ngIf="!variantData?.['isLoading'] && variantData?.['list']?.length; else variantLoading"
		>
			<section
				class="d-flex flex-column variant-items customTooltipPositionContainer position-relative"
			>
				<div class="checkbox-container more-item">
					<label class="checkbox" [for]="'select_all_'+item.id">
						<input
							type="checkbox"
							name="checkbox"
							[id]="'select_all_'+item.id"
							(change)="onVariantChange($event,null)"
							[checked]="variantData?.['list']?.length===variantData?.selected?.length"
							[disabled]="variantData?.['list']?.length===variantData?.selected?.length"
						/>
						<div class="checkbox-section"><i class="icon icon-Check"></i></div>
						<span class="fil-text user-select-none font-medium"
							>{{'Select all variants' | translate}}</span
						>
					</label>
				</div>
				<div
					class="checkbox-container more-item"
					*ngFor="let data of variantData?.['list']"
				>
					<label class="checkbox" [for]="item.id+'_'+data.id">
						<input
							type="checkbox"
							name="checkbox"
							[id]="item.id+'_'+data.id"
							[checked]="variantData?.selected?.includes(data.id)"
							(change)="onVariantChange($event,data)"
							[disabled]="variantData?.selected?.includes(data.id) && variantData?.selected?.length<2"
						/>
						<div class="checkbox-section"><i class="icon icon-Check"></i></div>
						<div class="variant-info-container d-flex align-items-center gap-8">
							<div class="image-container">
								<img
									*ngIf="data?.images?.length"
									[src]="data?.images[0]"
									alt=""
								/>
								<img
									*ngIf="!data?.images?.length"
									src="../../../../../assets/images/no-image.svg"
									alt=""
								/>
							</div>
							<div class="title-container d-flex flex-column">
								<span
									class="checkbox-data title customTooltipPosition"
									showTooltipOnTextOverflow
									[data]="data.name"
									>{{data.name}}</span
								>
								<section
									class="variant-extra-info d-flex flex-column font-medium"
								>
									<span class="price-text"
										>Price - {{data.currency.symbol}}{{data.price |
										numberSuffix: 2}}</span
									>
									<div
										*ngIf="commonFunctions.isIntegerOrDefined(data.discount)"
									>
										<span class="price-text">Discount - </span>
										<span class="badge-light-yellow badgeView discountView"
											>{{data.discount}}% OFF</span
										>
									</div>
								</section>
							</div>
						</div>
					</label>
				</div>
			</section>
		</ng-container>
		<ng-template #variantLoading>
			<div class="no-data-common">
				<div class="loading-panel">
					<img src="../../../../../assets/images/spinner.svg" alt="" />
				</div>
			</div>
		</ng-template>
	</div>
	<div class="modal-footer mt-3">
		<button
			type="button"
			class="btn btn-secondary btn-lg"
			(click)="modal.close('Close click')"
			translate
		>
			Cancel
		</button>
		<button
			type="button"
			class="btn btn-primary btn-lg"
			(click)="[updateVariantMapping()]"
			[disabled]="!variantData['updated']"
			translate
		>
			Update Mapping
		</button>
	</div>
</ng-template>
