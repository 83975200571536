import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'th[resizable]',
  templateUrl: './resizable.component.html',
  styleUrls: ['./resizable.component.scss']
})
export class ResizableComponent {

  @HostBinding("style.min-width.px")
  minWidth: number | null = null;

  @HostBinding("style.width.px")
  width: number | null = null;

  onResize(width: number) {
    this.width = width;
    this.minWidth=width;
    document.getElementById('ngtable-row').style.cursor = 'ew-resize';
  }

}