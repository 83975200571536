import { Injectable } from "@angular/core";
import { PreloadingStrategy, Route } from "@angular/router";
import { mergeMap } from "rxjs/operators";
import { Observable, of, timer } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class PreloadingStrategyService implements PreloadingStrategy {
  preload(route: Route, loadMe: () => Observable<any>): Observable<any> {
    if (route.data && route.data.preload) {
      let delay: number = route.data["delay"];
      if(delay){
        return timer(delay).pipe(
          mergeMap((_) => {
            return loadMe();
          })
        );
      }
      return loadMe()
    }
    return of(null);
  }
}