import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { NotificationsService } from '@ds-private-layouts/notifications/notifications.service';
import { AccessMatrixService } from '@ds-common-services/utility-services/access-matrix.service';
import { NotificationService } from '@ds-common-services/utility-services/notification.service';
import { RolePermissions } from '@ds-models/access-matrix.model';
import { TabListModel } from '@ds-shared/models/notification.model';

@Component({
  selector: 'notifications-action',
  templateUrl: './notifications-action.component.html',
  styleUrls: ['./notifications-action.component.scss']
})
export class NotificationsActionComponent implements OnInit {

  @Input() public actionData: any;
  @Input() public componentDetail: any;
  @Input() public selectedPage_size:any;
  @Input() public col:any;
  public deleteNotificationSubscription: Subscription;
  public permissions: RolePermissions = new RolePermissions();
  public tabList: TabListModel[] = [
		{
			id: 1,
			name: "Stores",
			value: "store",
			routePath: 'stores'
		},
    {
			id: 2,
			name: "Products",
			value: "product",
			routePath: 'products'
		},
    {
			id: 3,
			name: "Keywords",
			value: "keyword",
			routePath: 'keywords'
		},
    {
			id: 4,
			name: "Categories",
			value: "category",
			routePath: 'categories'
		},
    {
			id: 5,
			name: "Brands",
			value: "brand",
			routePath: 'brands'
		}
	];
  constructor(private modalService: NgbModal,private notificationService: NotificationsService,private route: ActivatedRoute,private notificationMessageService: NotificationService,private router: Router, private accessMatrixService: AccessMatrixService) {
    this.permissions = this.accessMatrixService.getPermissionByRoute();
   }

  ngOnInit(): void {
    
  }
  openModal(content) {
    this.modalService.open(content, { centered: true, windowClass: "customModal" });
  }
  editProfileRightPanel() {
    this.router.navigate([`/${this.route.snapshot['_urlSegment'].segments[0].path}/${this.route.snapshot['_urlSegment'].segments[1].path}/edit/${this.actionData.id}`]);
  }

  deleteProfile() {
    this.deleteNotificationSubscription?.unsubscribe();
    this.deleteNotificationSubscription = this.notificationService
			.deleteNotification(
				this.getTypeFromRoute(this.route.snapshot["_urlSegment"].segments[1].path),
				this.actionData.id
			)
			.subscribe(
				(res: any) => {
					if (res) {
						this.componentDetail.refreshData();
						this.notificationMessageService.setMessage(
							res["context_code"],
							res["summary"]
						);
					}
				},
				(err: any) => {
					this.notificationMessageService.setMessage(
						err?.error?.context_code,
						err?.error?.summary
					);
				}
			);

  }

  private getTypeFromRoute(type){
		const obj = this.tabList.find(o => o.routePath === type);
		return obj.value
	}

  ngOnDestroy(): void {
    if (this.deleteNotificationSubscription) {
      this.deleteNotificationSubscription.unsubscribe();
    }
  }

}
