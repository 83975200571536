import { Injectable } from "@angular/core";
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpErrorResponse
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { LocalStorageService } from "@ds-common-services/storage-services/local-storage.service";
import { NotificationService } from "@ds-common-services/utility-services/notification.service";
import { Router } from "@angular/router";
import { GlobalService } from "@ds-common-services/http-services/global.service";
import { CommonFunctionsService } from "@ds-common-services/utility-services/common-functions.service";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
	private token: any;
	public errorCounter = {
		count: 0,
		type: null
	};
	// notificationService: any;
	constructor(
		public note: NotificationService,
		private localStorageService: LocalStorageService,
		private globalService: GlobalService,
		private router: Router,
		private commonFunctions: CommonFunctionsService
	) {}

	intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<unknown>> {
		this.token = this.localStorageService.get("jwt");
		let isHarmonisationOn = "false";
		let customerId = "null";
		try {
			const checkForLocalStorage = this.localStorageService.get(
				this.localStorageService.userStorageKey
			)
				? JSON.parse(
						this.commonFunctions.getDecodedData(
							this.localStorageService.get(
								this.localStorageService.userStorageKey
							)
						)
				  )
				: null;
			isHarmonisationOn =
				checkForLocalStorage["data"]["harmonised_view"].toString();
			customerId = window.btoa(checkForLocalStorage["data"]["customer"]["id"]);
		} catch {
			// Do nothing, `isHarmonisationOn` remains "false" and customerId as null by default
		}
		let authReq = request;
		if (this.token) {
			const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
			// Clone the request and add custom headers
			if (request.url.includes("amazonaws.com")) {
				authReq = authReq.clone({
					headers: request.headers.set("Authorization", `Bearer ${this.token}`)
				});
			} else {
				authReq = request.clone({
					headers: request.headers
						.set("Authorization", `Bearer ${this.token}`)
						.set("X-Timezone", timeZone)
						.set("X-Harmonisation", isHarmonisationOn)
						.set("X-Customer", customerId)
				});
			}
		}
		return next.handle(authReq).pipe(
			tap((data: any) => {
				if (data?.status) {
					this.errorCounter.type = data.status;
					this.errorCounter.count = 0;
				}
			}),
			catchError((errorResponse: any) => {
				let errMsg: string;
				this.errorCounter.type = errorResponse.status;
				if (errorResponse.status === 401 || errorResponse.status === 403) {
					this.errorCounter.count++;
				}
				if (errorResponse instanceof HttpErrorResponse) {
					const err =
						errorResponse.message || JSON.stringify(errorResponse.error);
					errMsg = `${errorResponse.status} - ${errorResponse.statusText}`;
				} else {
					errMsg = errorResponse.message
						? errorResponse.message
						: errorResponse.toString();
				}
				if (errorResponse.status == 401) {
					this.globalService.logout();
					if (this.errorCounter.count === 1)
						this.note.setMessage(
							1100,
							this.commonFunctions.getSpecificTranslation("Session Expired!")
						);
				} else if (errorResponse.status == 403) {
					// user with no access should not be logged out
					// this.globalService.logout();
					// if (this.errorCounter.count === 1)
					// this.note.setMessage(1100, this.commonFunctions.getSpecificTranslation(errorResponse.error.summary));
				}
				// this.note.setMessage(1100,errMsg);
				return throwError(errorResponse);
			})
		);
	}
}
