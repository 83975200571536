<div class="quick-info" [ngClass]="{'customer':type=='customer'}" ngbAutofocus>
    <a class="label cursor-pointer" [ngClass]="{'bg-store':type=='customer'}"   target="_blank"
    *ngIf="productDetail?.store"
    [routerLink]="[commonFunctions?.redirectToScorecardInfo(productDetail.store_id,'store',true)?.params]">
    <span class="ellipsis" showTooltipOnTextOverflow [data]="productDetail?.store">{{productDetail?.store}}</span>
</a>
    <span *ngIf="!productDetail?.store">-</span>
    <div class="img-container"><img *ngIf="productDetail?.images && productDetail?.images?.length>0"
            src="{{productDetail?.images[0]}}" alt="">
        <img *ngIf="!productDetail?.images || productDetail?.images?.length==0"
            src="../../../../../assets/images/no-image.svg" alt="">
    </div>
    
    <div class='product-title-div'>
        <a class="product-name cursor-pointer on-hover-action" showMultiTooltipOnTextOverflow [data]="productDetail?.name"
            *ngIf="productDetail?.name"
            target="_blank"
            [routerLink]="[commonFunctions?.redirectToScorecardInfo(productId,'product',true)?.params]" >
            {{productDetail?.name}}</a>
        <div class="product-name" *ngIf="!productDetail?.name">-</div>
        <a href="{{productDetail?.url}}" target="_blank" class='navigate-link' placement="{{commonFunctions.rtlStatus ? 'right' : 'left'}}" ngbTooltip="{{'Go to Product Channel' | translate}}"
        tooltipClass="info-custom-tooltip"><i class="icon icon-Open-link"></i></a>
    </div>

    <div class="variantCount" *ngIf="productDetail?.variant_count">{{'In' | translate}} {{(productDetail?.variant_count)}} {{'Variants' | translate}}</div>
    <div class="noVariant" *ngIf="!productDetail?.variant_count">&nbsp;</div>
    <div class="price" *ngIf="productDetail?.price">{{productDetail?.currency?.symbol}}{{productDetail?.price | numberSuffix: 2}}</div>
    <div class="price" *ngIf="!productDetail?.price">-</div>
    <div class="brand-name">{{productDetail?.brand?productDetail?.brand:"-"}}</div>
    <div class="category" showTooltipOnTextOverflow [data]="productDetail?.category"
        *ngIf="productDetail?.category">
        {{productDetail?.category}}</div>
    <div class="category" *ngIf="!productDetail?.category">-</div>
    <div class="marketplace d-flex align-items-center gap-6" *ngIf="productDetail?.marketplace">
        <app-marketplace-store-logo [storeName]="productDetail?.marketplace">
        </app-marketplace-store-logo>
        <span>{{productDetail?.marketplace}}</span>
    </div>
    <div class="marketplace" *ngIf="!productDetail?.marketplace">-</div>
    <div *ngIf="productDetail?.discount" class="voucher-badge discount-value d-inline-block">{{productDetail?.discount}}% {{'OFF' | translate}}</div>
    <div *ngIf="!productDetail?.discount" class="voucher-null discount-value d-flex align-items-center">-</div>
    <div class="vouchers-available d-flex align-items-center gap-2"
        *ngIf="productDetail?.vouchers && productDetail?.vouchers?.length>0">
        <span class="voucher-badge ellipsis d-block" showTooltipOnTextOverflow [data]="productDetail?.vouchers[0]"></span>
        <span *ngIf="productDetail?.vouchers?.length>1">+{{productDetail?.vouchers?.length-1}}</span>
    </div>
    <div class="vouchers-available d-flex align-items-center"
        *ngIf="!productDetail?.vouchers || productDetail?.vouchers?.length==0">-</div>
    <ng-template #starsOnly let-fill="fill">
        <span class="stars" [class.full]="fill === 100">
            <span class="half" [style.width.%]="fill">&#x02605;</span>&#x02605;
        </span>
    </ng-template>
    <div class="rating" *ngIf="productDetail?.rating">
        <ngb-rating [rate]="productDetail?.rating" [starTemplate]="starsOnly" [readonly]="true" [max]="5">
        </ngb-rating>
        <span class="associateText">{{productDetail?.rating}}/5</span>
    </div>
    <div class="rating d-flex align-items-center" *ngIf="!productDetail?.rating">-</div>
    <div class="date" *ngIf="productDetail?.updated_at">
        {{commonFunctions.formatSimpleDate(productDetail?.updated_at)}}</div>
    <div class="date" *ngIf="!productDetail?.updated_at">-</div>
</div>