import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AccessMatrixService } from '@ds-common-services/utility-services/access-matrix.service';
import { AccessMatrix } from '@ds-enums/access-matrix.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private accessMatrixService: AccessMatrixService, private router: Router) { }
  private actionTypeList = ['add', 'update', 'edit'];

  private isPathActive (urlStr: String) {
    return this.actionTypeList.some((ele:string) => urlStr.includes(ele));
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const path = state.url.split('/')[1];
      const permission = this.accessMatrixService.getPermissionByModule(path);
      if(permission.full_access || (permission.read_access && !this.isPathActive(state.url) )|| (path===AccessMatrix['User Management'] && !permission.read_access)){
        return true;
      }
    this.accessMatrixService.navigateAfterLogin();
    return false;
  }
  
}
